.about-item
	@apply bg-white p-5 space-y-2 xl:p-8
	.icon
		@apply w-24 h-24 flex-center
	.number
		@apply header-40
		@apply font-bold text-primary-900 leading-[calc(48/40)]
	.caption
		@apply body-14 font-medium text-black

.line-b-primary
	@apply pb-9 border-b-4 border-primary-900

.box-slogan
	@apply w-max mx-auto p-5 bg-primary-900 section-header-32 font-bold text-white uppercase text-center xl:px-8

.university-name
	@apply sub-header-20 font-bold text-black
	.highlight
		@apply text-primary-900

.about-1
	.image-content
		@apply py-10 xl:py-16
	.about-list
		@apply space-y-5 xl:space-y-14
	.about-item
		@apply p-0
	.full-content
		+scroll-y
		@apply rem:max-h-[468px] overflow-y-auto pr-8
		ul
			@apply pl-5
		li
			@apply marker:text-black

.about-3-item
	@apply p-5 bg-neutral-50 flex flex-col justify-center gap-4 xl:p-8 xl:rem:min-h-[224px]

.vision-mission-item
	@apply bg-neutral-50 h-full
	.image
		@apply aspect-[736/414]
	.caption
		@apply p-5 flex flex-col gap-4 xl:p-8
	.title
		@apply sub-header-24 font-bold text-primary-900
	.brief
		@apply body-16 font-normal text-black

.about-3
	.image-content
		@apply py-10 xl:py-16

.about-4
	@apply rem:border-b-[34px] border-primary-900
	.full-content
		@apply py-6 border-t border-secondary-light-gray
		hr
			@apply my-6 border-secondary-light-gray
		h3
			@apply sub-header-20 font-bold mb-3
	.box-content
		@apply xl:pb-16 xl:rem:max-w-[640px]
	.image
		@apply -mb-2
	&.is-bg-leaner
		background: linear-gradient(180deg, rgba(238, 245, 246, 0.00) 0%, #E4E4E4 100%)

.about-5
	.image
		@apply mt-10 xl:mt-16

.count-up-item
	@apply flex flex-col justify-center items-center body-16 font-bold text-black text-center
	.count-up
		@apply header-48 text-primary-900

.count-up-list
	@apply p-5 grid grid-cols-2 gap-5 border-b border-primary-900 justify-center xl:p-8 xl:flex xl:justify-center xl:gap-10
	> *
		@apply xl:flex-1

.about-6-item
	.image
		@apply aspect-[736/490]
	.caption
		@apply p-5 xl:px-6 xl:py-8
	.title
		@apply sub-header-20 font-bold text-black

.about-6
	.full-content
		@apply mt-10 xl:mt-16
	.swiper-relative
		@apply mt-10 xl:mt-16

.scientist-item
	@apply sub-header-20 font-medium text-black flex items-center justify-between gap-3 p-3 rounded-2 bg-white transition-all hover:bg-neutral-50 xl:px-8
	.arrow
		@apply flex-center w-11 h-11 rounded-1 text-lg text-white bg-primary-900

.scientist-list
	@apply grid grid-cols-1 rem:gap-[14px] md:grid-cols-2 md:gap-x-8

.about-7
	.scientist-list
		@apply mt-10 xl:mt-16

.year-slide
	@apply cursor-pointer
	.dot
		@apply w-7 h-7 rounded-full bg-transparent border border-transparent transition-all mx-auto flex-center relative
		&::before
			content: ''
			@apply w-4 h-4 bg-white border border-primary-900 rounded-full transition-all
	.year
		@apply text-center sub-header-20 font-bold text-neutral-700 mt-3 transition-all
	&.swiper-slide-thumb-active
		.dot
			@apply border-primary-900 bg-white
			&::before
				@apply bg-primary-900
		.year
			@apply scale-120 text-primary-900

.history-top
	@apply relative
	&::before
		content: ''
		@apply absolute rem:top-[10px] left-0 w-full h-[1px] bg-neutral-200 pointer-events-none

.leader-top
	@apply relative
	&::before
		content: ''
		@apply absolute rem:top-[10px] left-0 w-full h-[1px] bg-neutral-200 pointer-events-none

.history-item
	.image
		@apply aspect-[992/560]
	.caption
		@apply flex flex-col justify-center rem:gap-[22px] p-5 bg-white xl:p-10 xl:pl-14
	.year
		@apply sub-header-20 font-bold text-white bg-primary-900 px-5 w-max rem:py-[10px]
	.brief
		@apply body-16 font-normal text-black
	@screen xl
		@apply flex
		.image
			@apply w-[calc(992/1504*100%)]
		.caption
			@apply flex-1

.leader-item
	.image
		@apply aspect-[302/384]
	.caption
		@apply body-14 font-medium text-neutral-700 p-5 text-center flex flex-col justify-between items-center rem:gap-[7px]
	.name
		@apply body-14 font-bold text-primary-900

.about-8
	& + .about-9
		@apply pt-0
	.box-history
		@apply mt-8

.about-9
	.leader-bot
		> .swiper
			@apply xl:-mx-20 xl:px-20
			> .swiper-wrapper
				> .swiper-slide
					@apply opacity-0 pointer-events-none
					&.swiper-slide-active
						@apply opacity-100 pointer-events-auto

.about-10
	.image
		box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.08)
	.image-content
		@apply mt-10 xl:mt-16

.about-11
	.main-tabslet
		@apply mt-10 xl:mt-16
	.card-item
		.caption
			@apply bg-white

.table-responsive
	table
		@apply w-full
	th
		@apply p-5 bg-primary-900 sub-header-20 font-bold text-white border border-primary-blue-100
	td
		@apply px-5 py-3 body-16 font-normal text-black border border-primary-blue-100 transition-all first:font-bold
	a
		@apply body-16 font-normal text-primary-700 hover-underline hover:text-primary-900
	tbody
		tr
			@apply bg-white even:bg-neutral-50
			&:hover
				td
					@apply text-primary-900
					&:not(:first-child)
						text-shadow: 0.7px 0px 0 theme('colors.primary.900')
						a
							text-shadow: none

.about-table
	.tabslet-tabs
		@apply flex-center flex-wrap gap-x-5 gap-y-3
		.btn-solid
			background-size: 0
			@apply hover:text-white
		li
			@apply border-none p-0
			&.active
				.btn-solid
					@apply bg-primary-900 text-white
