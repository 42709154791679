.date-status-flex
	@apply flex items-center gap-3

.status
	@apply bg-primary-900 px-3 py-1 body-14 font-medium text-white

.date-share
	@apply flex items-center justify-between gap-4

.news-detail-section
	.news-detail-title
		@apply leading-[calc(48/40)]
	.news-date
		@apply text-neutral-400
	.date-share
		@apply mt-6
		.date-status-flex
			@apply m-0
	.date-status-flex
		@apply mt-6
		.news-date
			@apply mt-0
	.status
		@apply text-white text-sm py-1 px-3 bg-primary-900 ml-3
	.full-content
		@apply pt-6 mt-6 border-t border-neutral-gray-d9
	.bottom-wrap
		@apply pb-5 border-b border-b-neutral-D9D9D9 flex justify-start items-center
		@screen xl
			@apply pb-6

.briefcontent
	@apply  font-bold leading-tight text-neutral-950 text-justify pt-4 mb-2
	*
		@apply  font-bold leading-tight text-neutral-950 text-justify
	@screen xl
		@apply pt-6

.events-other-section
	&:is(.bg-neutral-50)
		.event-item
			@apply bg-white
