.footer-title
	@apply py-2 body-18 font-bold text-white

.footer-menu
	@apply space-y-3
	a
		@apply body-16 font-medium text-white hover-underline

.footer-info
	@apply space-y-3 xl:pr-5
	li
		@apply flex
	.icon
		@apply w-6 h-6 flex items-center text-current text-base
	.content
		@apply flex-1 body-14 font-medium text-white
		p
			@apply body-14 font-medium text-white

.footer-block
	@apply space-y-3 mb-7 xl:mb-10

.copyright
	@apply text-[12px] font-medium text-neutral-gray-200 text-center

.certification-item
	@apply flex items-center gap-3 text-xs font-medium text-primary-50 text-justify lg:rem:w-[400px] xl:rem:w-[304px]
	img
		@apply size-19 rounded-1 object-contain bg-primary-50

.footer-top
	@apply py-10 flex items-center flex-wrap justify-between gap-10 lg:flex-nowrap
	.logo
		@apply aspect-[360/160] rem:max-w-[360px] overflow-hidden
		img, svg
			@apply w-full h-full object-cover
	.right
		@apply flex flex-col gap-3 xl:flex-row
	.top
		@apply flex flex-col gap-3
	.iso
		@apply flex flex-col gap-3
		.certification-item
			@apply xl:ml-auto xl:mr-0

.footer-mid
	@apply py-5 border-t border-[#BB1A29]
	@screen md
		@apply columns-2 gap-8
		.footer-block
			@apply break-inside-avoid
	@screen xl
		@apply columns-4 pb-26

.footer-bot
	@apply py-5 border-t border-[#BB1A29] flex flex-col gap-6 xl:py-8

footer
	@apply text-white pb-2
