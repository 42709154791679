.document-section
	.table-responsive
		th
			@apply text-left first:w-20
		a
			@apply text-black hover:text-primary-900
		th, td
			@apply first:text-center
		td
			&:last-child
				@apply rem:w-[244px]
				a
					@apply mx-auto flex-center w-max text-primary-700 hover:text-primary-900
