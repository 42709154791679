.news-date
	@apply body-14 font-medium text-neutral-700

.card-item
	@apply flex flex-col h-full
	.image
		@apply aspect-[352/236] img-zoom
	.caption
		@apply flex-1 flex flex-col gap-3 p-5 bg-neutral-50 border-b-4 border-neutral-50 rounded-b-4 transition-all xl:p-6 xl:pb-8
	.card-title
		@apply body-16 font-bold text-black hover:text-primary-900
	&:hover
		.caption
			@apply border-primary-900

.news-item
	@apply flex flex-col bg-white rounded-b-4 overflow-hidden relative
	.image
		@apply aspect-[480/322] img-zoom
	.caption
		@apply flex-1 flex flex-col gap-3 p-5 rounded-b-4 border-b-4 border-neutral-50 transition-all xl:p-6 xl:pb-8
	.news-title
		@apply body-18 font-bold text-black line-clamp-3 hover:text-primary-900
	&:hover
		.image
			img
				@apply scale-110
		.caption
			@apply border-primary-900
