.courses-item
	@apply flex flex-col gap-3 py-5 border-b border-neutral-200 first:pt-0
	.title
		@apply body-18 font-bold text-black
	.brief
		@apply body-16 font-normal text-black

.site-nav
	@apply flex flex-wrap gap-5 justify-center
	a
		@apply flex-center h-11 rounded-full px-5 py-2 body-16 font-medium text-primary-900 border border-primary-900 bg-white hover:bg-primary-900 hover:text-white
	li
		&.active
			a
				@apply bg-primary-900 text-white

.academic-item
	@apply bg-neutral-50 rounded-b-4 overflow-hidden
	.image
		@apply aspect-[480/322] img-zoom
	.caption
		@apply p-5 xl:p-6
	.title
		@apply body-18 font-bold text-black hover:text-primary-900
	&:hover
		.image
			img
				@apply scale-110
		.title
			@apply text-primary-900

.academic-section
	// @apply even:bg-neutral-50
	// .site-title
	// 	@apply rem:max-w-[727px]
	// .site-desc
	// 	@apply rem:max-w-[608px]
	.academic-list
		@apply grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3

.academic-nav
	@apply flex items-center

.box-categories
	@apply rounded-4 bg-white shadow-light p-5 xl:p-6 xl:pb-2
	.box-head
		@apply pb-4 border-b border-neutral-gray-d9
	.category-title
		@apply flex justify-between gap-3 body-18 font-bold text-neutral-700 py-4 hover:text-primary-900
		.category-toggle
			@apply text-xl text-neutral-500 cursor-pointer
	.category-list
		> li
			@apply border-b border-neutral-gray-d9 last:border-none
			&.active
				@apply border-transparent
				.category-title
					@apply text-primary-950
					.category-toggle
						@apply text-current before:content-['\f068']
	.sub-category
		@apply mt-4 rounded-4 p-5 space-y-3 bg-neutral-50 hidden xl:p-6
		a
			@apply body-16 font-normal text-black hover:text-primary-950
		li
			&.active
				a
					text-shadow: 0.7px 0 0 theme('colors.primary.950')
					@apply text-primary-950

.course-content
	@apply body-16 font-normal text-black
	> * + *
		@apply mt-4

.box-course-content
	@apply prose max-w-none
	@apply body-16 font-normal text-black p-5 bg-neutral-50 xl:p-8
	h3
		@apply sub-header-24 font-bold text-primary-900 mb-3 mt-0
	ul
		@apply pl-5
		li
			@apply marker:text-primary-900
