.event-item
	@apply flex flex-col bg-neutral-50 rounded-b-4 border-b-4 border-neutral-50 overflow-hidden relative
	.image
		@apply aspect-[480/322] img-zoom
	.caption
		@apply flex-1 flex flex-col gap-3 p-5 transition-all xl:p-6 xl:pb-8
	.news-date
		@apply text-neutral-400
	.event-title
		@apply body-18 font-bold text-black line-clamp-3 hover:text-primary-900
	.brief
		@apply body-16 font-normal text-neutral-900
	&:hover
		.image
			img
				@apply scale-110
		.caption
			@apply border-primary-900
	@screen md
		&.is-big
			@apply flex-row rounded-none
			.image
				@apply w-[calc(992/1504*100%)] aspect-[992/660]
			.caption
				@apply justify-center p-8 gap-6
			.event-title
				@apply sub-header-24 line-clamp-4
	@screen xl
		&.is-big
			.caption
				@apply p-16

.event-list
	@apply grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3

.event-list-section
	.event-item
		@apply md:first:col-span-2 lg:first:col-span-3
