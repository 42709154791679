.pkbm-banner
	.image
		@apply aspect-[1920/688]

.pkbm-home-1
	.pkbm-desc
		@apply rem:max-w-[1152px]
	.count-up-list
		@apply border-y border-neutral-400

.profile-item
	@apply text-center border-b border-neutral-200
	.image
		@apply rounded-full overflow-hidden mx-auto aspect-square img-zoom
	.caption
		@apply flex flex-col justify-center gap-1 p-4 xl:py-8
	.name
		@apply sub-header-20 font-bold text-red-900
	.sub-name
		@apply body-14 font-medium text-neutral-500

.partner-item
	.image
		@apply flex-center bg-white h-22 border border-neutral-200 xl:rem:h-[132px]

.pkbm-home-6
	.site-desc
		@apply rem:max-w-[809px]
