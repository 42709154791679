.button
	@apply flex flex-wrap gap-5

.link
	@apply inline-flex items-center text-center gap-2 text-base font-medium text-neutral-500 leading-normal hover-underline hover:text-a7

.btn

.btn-solid
	@apply flex-center gap-2 h-11 px-4 py-2 text-base font-medium text-white bg-primary-900 rounded-1 transition-all duration-200 ease-in-out hover:bg-primary-950 hover:text-white
	&.is-white
		@apply bg-white text-primary-900 hover:bg-primary-950 hover:text-white

.btn-lined
.btn-download
	@apply flex justify-center items-center py-0 px-2
	em,span
		@apply text-primary-700 text-base  leading-none
	em
		@apply mr-3

