.assurance-detail-title
	@apply leading-[calc(48/40)]

.assurance-detail-section
	.news-date
		@apply rem:mt-[22px]
	.brief-content
		@apply body-16 font-normal text-black rem:mt-[22px] pt-5 border-t border-neutral-gray-d9 xl:pt-8
	.full-content
		@apply pt-10 rem:max-w-[1248px] mx-auto xl:pt-15
