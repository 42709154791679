@charset "UTF-8";
/**
 * This injects Tailwind's base styles and any base styles registered-500 by
 * plugins.
 */
@tailwind base {}/**
 * This injects Tailwind's component classes and any component classes
 * registered-500 by plugins.
 */
@tailwind components {}/**
 * This injects Tailwind's utility classes and any utility classes registered-500
 * by plugins.
 */
@tailwind utilities {}/**
 * Use this directive to control where Tailwind injects the hover, focus,
 * responsive, dark mode, and other variants of each class.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind variants {}.edit-link i:before {
  content: '✏️'; }

html, body {
  @apply scroll-pt-22 md:scroll-pt-27 xl:scroll-pt-30 {} }

.tabslet-tabs {
  @apply flex items-center gap-4 overflow-x-auto md:overflow-visible md:justify-center {} }
  .tabslet-tabs a {
    @apply flex-center text-center body-16 font-medium text-neutral-950 hover-underline whitespace-nowrap {} }
  .tabslet-tabs li {
    @apply w-max {} }
    .tabslet-tabs li + li {
      @apply md:pl-4 md:border-l md:border-neutral-300 {} }
    .tabslet-tabs li.active a {
      @apply text-primary-900 hover-underline-active {} }

.nav-section {
  @apply bg-white sticky top-22 z-[997] px-4 md:top-27 xl:top-30 {} }
  .nav-section::before {
    content: '';
    @apply absolute bottom-0 left-0 w-full h-px bg-neutral-300 pointer-events-none z-1 {} }
  .nav-section .nav-heading-flex {
    @apply flex gap-3 xl:gap-6 {} }
  .nav-section .nav-heading {
    @apply body-18 font-bold text-white bg-primary-900 p-3 hidden md:block xl:px-5 xl:py-6 {} }
  .nav-section .nav-list {
    @apply relative z-1 flex gap-4 overflow-x-auto p-0 md:overflow-visible md:justify-center {} }
    .nav-section .nav-list a {
      @apply body-16 font-medium text-neutral-950 hover-underline flex-center text-center rem:py-[10px] hover-underline {} }
    .nav-section .nav-list li + li {
      @apply pl-4 relative {} }
      .nav-section .nav-list li + li::before {
        content: '';
        @apply absolute top-1/2 -translate-y-1/2 left-0 w-px h-4 bg-neutral-300 pointer-events-none {} }
    .nav-section .nav-list li.active a {
      @apply text-primary-900 hover-underline-active {} }

@screen xl {
  .nav-section.is-pkbm .nav-list {
    @apply gap-8 {} }
    .nav-section.is-pkbm .nav-list li + li {
      @apply pl-8 {} } }

@layer base {
  html, body {
    font-size: 16px;
    @apply scroll-smooth {} }
  @screen xl {
    html, body {
      font-size: 1vw; } }
  body {
    @apply font-sans overflow-y-overlay text-main {} }
  h1 {
    @apply text-3xl font-bold {} }
  h2 {
    @apply text-2xl font-bold {} }
  h3 {
    @apply text-xl font-bold {} }
  h4 {
    @apply text-lg font-bold {} }
  h5 {
    @apply text-base font-bold {} }
  h6 {
    @apply text-sm font-bold {} }
  a {
    @apply transition-all ease-linear duration-200 hover:text-current {} }
  img {
    @apply max-w-full inline {} }
  main, div {
    @apply xl:text-base {} } }

@layer components {
  @screen xs {
    .container {
      @apply max-w-full {} } }
  @screen sm {
    .container {
      @apply max-w-[96vw] {} } }
  @screen xl {
    .container {
      @apply max-w-[calc(1534/1920*100rem)] {} } }
  .equal-height .row > [class*=col] > * {
    @apply h-full {} }
  .equal-height .swiper-slide {
    @apply h-auto {} }
    .equal-height .swiper-slide > * {
      @apply h-full {} }
  .lozad-bg {
    @apply bg-center bg-no-repeat bg-cover {} }
  .img-cover {
    @apply relative overflow-hidden {} }
    .img-cover img {
      @apply w-full h-full object-cover {} }
  .img-contain {
    @apply relative overflow-hidden {} }
    .img-contain img {
      @apply w-full h-full object-contain {} }
  .img-zoom {
    @apply relative overflow-hidden {} }
    .img-zoom img {
      @apply w-full h-full object-cover transition-all duration-300 ease-linear hover:scale-105 {} } }

@layer utilities {
  .container-fuild {
    @apply px-4 {} }
  .section {
    @apply py-10 xl:py-15 {} }
  .section-t {
    @apply pt-10 xl:pt-15 {} }
  .section-b {
    @apply pb-10 xl:pb-15 {} }
  .section-small {
    @apply py-8 xl:py-10 {} }
  .section-t-small {
    @apply pt-8 xl:pt-10 {} }
  .section-b-small {
    @apply pb-8 xl:pb-10 {} }
  .section-large {
    @apply py-15 xl:py-20 {} }
  .section-t-large {
    @apply pt-15 xl:pt-20 {} }
  .section-b-large {
    @apply pb-15 xl:pb-20 {} }
  .row {
    @apply flex flex-wrap -mx-4 {} }
    .row > * {
      @apply px-4 {} }
  .no-gutters {
    @apply mx-0 {} }
    .no-gutters > * {
      @apply px-0 {} }
  .swiper-relative {
    @apply relative {} }
  .writing-mode-tb-rl {
    writing-mode: vertical-rl;
    text-orientation: mixed; }
  .media-scale {
    @apply relative block h-0 overflow-hidden {} }
    .media-scale img, .media-scale iframe, .media-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .img-scale {
    @apply relative block h-0 overflow-hidden {} }
    .img-scale img {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .iframe-scale {
    @apply relative block h-0 overflow-hidden {} }
    .iframe-scale iframe {
      @apply absolute min-w-full w-0 h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .video-scale {
    @apply relative block h-0 overflow-hidden {} }
    .video-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .bg-bright-grey {
    @apply backdrop-blur-25 {} }
  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 {} }
  .filter-white {
    @apply brightness-0 invert {} }
  .img-zoom-in img {
    transition: .35s all  ease-in-out; }
  .img-zoom-in:hover img {
    @apply scale-110 {} }
  .hover-underline {
    @apply relative {}    background: linear-gradient(0deg, theme("colors.current"), theme("colors.current")) no-repeat right bottom/0 theme("spacing[px]");
    transition: background-size 350ms; }
    .hover-underline:hover {
      background-size: 100% theme("spacing[px]");
      background-position-x: left; }
  .hover-underline-active {
    background-size: 100% theme("spacing[px]");
    background-position-x: left; }
  .desktop-only {
    @apply hidden lg:block {} }
  .mobile-only {
    @apply block lg:hidden {} }
  .text-xs {
    @apply text-[14px] xl:text-[calc(12/1920*100rem)] {} }
  .img-704 {
    @apply rem:max-w-[704px] {} }
  .img-768 {
    @apply rem:max-w-[768px] {} } }

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900); }

.fa, .fas, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin, .fad, .fa-duotone, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }

::root, ::host {
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands"; }

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype"); }

.fab,
.fa-brands {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

::root, ::host {
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype"); }

.fal,
.fa-light {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300; }

::root, ::host {
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype"); }

.far,
.fa-regular {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

::root, ::host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype"); }

.fas,
.fa-solid {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900; }

::root, ::host {
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.ttf") format("truetype"); }

.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 100; }

.header-48 {
  @apply text-[30px] leading-snug xl:text-5xl {} }

.header-40 {
  @apply text-[28px] leading-snug xl:text-40 {} }

.section-header-32 {
  @apply text-[24px] leading-tight xl:text-32 {} }

.sub-header-24 {
  @apply text-[20px] leading-snug xl:text-2xl {} }

.sub-header-20 {
  @apply text-[18px] leading-snug xl:text-xl {} }

.body-18 {
  @apply text-[17px] leading-tight xl:text-lg {} }

.body-16 {
  @apply text-[16px] leading-tight xl:text-base {} }

.body-14 {
  @apply text-[14px] leading-tight xl:text-sm {} }

.label-12 {
  @apply text-[12px] leading-snug xl:text-sm {} }

.regular-18 {
  @apply text-[16px] leading-normal xl:text-lg {} }

.site-title {
  @apply header-48 font-bold text-primary-900 {} }

.site-sub-title {
  @apply section-header-32 font-bold text-black {} }

.site-desc {
  @apply body-16 font-normal text-black {} }
  .site-desc > * {
    @apply my-4 first:mt-0 last:mb-0 {} }
  .site-desc ul {
    @apply list-disc pl-5 space-y-4 {} }

.full-content {
  @apply prose max-w-none {}  @apply body-16 font-normal text-black leading-normal {} }
  .full-content > * {
    @apply first:mt-0 last:mb-0 {} }
  .full-content p img {
    @apply m-0 {} }
  .full-content th, .full-content td {
    @apply rem:py-[10px] {} }
  .full-content tbody tr {
    @apply border-y border-neutral-300 {} }
  .full-content tbody th {
    @apply font-bold text-primary-900 {} }

.fullcontent {
  @apply font-normal leading-[1.4] text-neutral-090909 text-justify pt-3 {} }
  .fullcontent * {
    @apply font-normal leading-[1.4] text-neutral-090909 text-justify {} }
  @media screen and (max-width: 1024px) {
    .fullcontent {
      @apply text-base {} }
      .fullcontent * {
        @apply text-base {} } }
  .fullcontent img, .fullcontent iframe {
    @apply block mx-auto my-4  overflow-hidden {}    margin: 16px auto !important; }
  .fullcontent p {
    @apply mb-3 text-base {} }
    .fullcontent p * {
      @apply text-base {} }
    @media screen and (max-width: 1024px) {
      .fullcontent p {
        @apply text-base {} }
        .fullcontent p * {
          @apply text-base {} } }
  .fullcontent ul, .fullcontent ol {
    list-style-type: disc;
    padding-left: 0; }
    .fullcontent ul li, .fullcontent ol li {
      @apply mb-3 flex items-start {} }
      .fullcontent ul li::before, .fullcontent ol li::before {
        content: '';
        @apply block mt-2 pointer-events-none z-10  transition w-[8px] h-[8px] rounded-full mr-3 {}        min-width: 8px; }
  .fullcontent hr {
    @apply block my-4 {} }
  .fullcontent h2, .fullcontent h3, .fullcontent h4, .fullcontent h5 {
    @apply mb-4 font-bold text-primary-900 {} }
  .fullcontent strong {
    font-weight: 700 !important; }
    .fullcontent strong * {
      font-weight: 700 !important; }
  .fullcontent h2 {
    @apply text-4xl {} }
    @media screen and (max-width: 576px) {
      .fullcontent h2 {
        @apply text-3xl {} } }
  .fullcontent h3 {
    @apply text-3xl {} }
    @media screen and (max-width: 576px) {
      .fullcontent h3 {
        @apply text-2xl {} } }
  .fullcontent h4 {
    @apply text-xl {} }
  .fullcontent h5 {
    @apply text-lg {} }
  .fullcontent .row {
    @apply mb-0 {} }
  .fullcontent table {
    @apply w-full {} }
    .fullcontent table tbody td {
      @apply text-black text-base py-3 font-normal border-t border-t-neutral-300 border-b border-b-neutral-300 {} }
      .fullcontent table tbody td * {
        @apply text-black text-base font-normal {} }
      .fullcontent table tbody td:first-child {
        @apply font-bold text-primary-900 {} }
        .fullcontent table tbody td:first-child * {
          @apply font-bold text-primary-900 {} }
      .fullcontent table tbody td:nth-child(2) {
        @apply pl-6 {} }

.button {
  @apply flex flex-wrap gap-5 {} }

.link {
  @apply inline-flex items-center text-center gap-2 text-base font-medium text-neutral-500 leading-normal hover-underline hover:text-a7 {} }

.btn-solid {
  @apply flex-center gap-2 h-11 px-4 py-2 text-base font-medium text-white bg-primary-900 rounded-1 transition-all duration-200 ease-in-out hover:bg-primary-950 hover:text-white {} }
  .btn-solid.is-white {
    @apply bg-white text-primary-900 hover:bg-primary-950 hover:text-white {} }

.btn-download {
  @apply flex justify-center items-center py-0 px-2 {} }
  .btn-download em, .btn-download span {
    @apply text-primary-700 text-base  leading-none {} }
  .btn-download em {
    @apply mr-3 {} }

#buttonMenu {
  @apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none m-0 p-0 bg-none ml-3 {} }
  #buttonMenu .line {
    @apply absolute block h-[2px] w-full bg-primary-900 rounded-full opacity-100 left-0 rotate-0 transition-all {} }
    #buttonMenu .line:nth-child(1) {
      @apply top-[4px] {} }
    #buttonMenu .line:nth-child(2) {
      @apply top-1/2 -translate-y-1/2 {} }
    #buttonMenu .line:nth-child(3) {
      @apply bottom-[4px] {} }
  #buttonMenu #pulseMe {
    @apply flex justify-center items-center absolute top-1/2 left-1/2 min-w-[34px] w-[30px] h-[34px] -translate-x-1/2 -translate-y-1/2 z-1 {} }
  #buttonMenu .bar {
    @apply bg-primary-900 rounded-1 absolute shadow-bar {} }
    #buttonMenu .bar.left {
      @apply w-px animate-left-bar {} }
    #buttonMenu .bar.top {
      @apply h-px animate-top-bar {} }
    #buttonMenu .bar.right {
      @apply w-px animate-right-bar {} }
    #buttonMenu .bar.bottom {
      @apply h-px animate-bottom-bar {} }
  #buttonMenu.open .line:nth-child(2), #buttonMenu:hover .line:nth-child(2) {
    @apply w-[15px] {} }
  #buttonMenu.open .bar, #buttonMenu:hover .bar {
    @apply hidden {} }

@screen xl {
  #buttonMenu {
    display: none !important; } }

.alert {
  @apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm {} }
  .alert button {
    @apply hidden {} }
  .alert.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .alert.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }

.backdrop {
  backdrop-filter: blur(8px);
  @apply fixed z-[500] w-full h-full top-0 left-0 bg-black/50 pointer-events-none opacity-0 transition-all {} }
  .backdrop.open {
    @apply opacity-100 pointer-events-auto {} }
  .backdrop.search-backdrop {
    @apply z-[1500] {} }

.cta-fixed {
  @apply fixed z-100 bottom-10 right-3 xl:right-10 {} }
  .cta-fixed ul {
    @apply space-y-1 {} }
  .cta-fixed li > * {
    @apply flex-center flex-col gap-1 w-16 h-16 bg-neutral-gray-300 rem:text-[12px] font-medium text-white shadow hover:bg-primary-800 {} }
  .cta-fixed li i {
    @apply text-2xl w-8 h-8 flex-center {} }
  .cta-fixed li img {
    @apply w-7 h-7 object-contain {} }
  .cta-fixed .back-to-top {
    @apply opacity-0 pointer-events-none  transition-all duration-200 {} }
    .cta-fixed .back-to-top > * {
      @apply bg-primary-800 hover:bg-primary-950 {} }
    .cta-fixed .back-to-top.active {
      @apply opacity-100 pointer-events-auto {} }

.social-list ul {
  @apply flex flex-wrap justify-center rem:gap-[10px] {} }

.social-list a {
  @apply w-12 h-12 rounded-1 flex-center text-white text-xl bg-[#BB1A29] shadow hover:bg-primary-950 {} }

.dropdown {
  @apply relative {} }
  .dropdown .dropdown-toggle {
    @apply text-neutral-700 flex items-center justify-between gap-4 cursor-pointer {} }
  .dropdown .dropdown-menu {
    @apply absolute top-full mt-5 left-0 w-full min-w-max z-10 bg-white rounded-1 px-5 py-3 shadow opacity-0 pointer-events-none transition-all duration-200 ease-linear {} }
  .dropdown.open .dropdown-toggle i, .dropdown.open .dropdown-toggle em {
    @apply rotate-180 {} }
  .dropdown.open .dropdown-menu {
    @apply opacity-100 pointer-events-auto {} }

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
textarea,
select {
  @apply bg-white border border-neutral-600 pl-6 pr-10 py-2 rounded-2 text-base font-normal leading-normal text-primary-700 w-full bg-no-repeat h-12 m-0 {}  @apply focus:outline-none focus:ring-2 focus:ring-primary-700 focus:ring-offset-0 {} }

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'] {
  @apply placeholder:text-neutral-700 {}  @apply disabled:bg-neutral-700/50 disabled:border-neutral-700/75 disabled:text-neutral-900 {}  @apply read-only:bg-neutral-700/50 read-only:border-neutral-700/75 read-only:text-neutral-900 {} }

select {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%23A6BD39' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M6 8l4 4 4-4'/></svg>");
  @apply bg-right appearance-none {} }

select[multiple] {
  @apply min-h-[calc(48/1920*100rem)] py-3 h-auto bg-none {} }

textarea {
  @apply h-20 py-4 {} }

input[type='file'] {
  @apply hidden {} }
  input[type='file'] ~ label {
    @apply relative w-max bg-neutral-600 border-none h-12 px-6 py-3 text-base text-primary-700 font-normal flex-center gap-3 cursor-pointer m-0 transition-all duration-200 ease-linear hover:bg-neutral-700 {} }

input[type='checkbox'] {
  @apply hidden {} }
  input[type='checkbox'] ~ label {
    @apply relative pl-5 {} }
    input[type='checkbox'] ~ label::before {
      @apply font-awesome content-['\f0c8'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal {} }
  input[type='checkbox']:checked ~ label::before {
    @apply content-['\f14a'] {} }

input[type='radio'] {
  @apply hidden {} }
  input[type='radio'] ~ label {
    @apply relative pl-5 {} }
    input[type='radio'] ~ label::before {
      @apply font-awesome content-['\f111'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal {} }
  input[type='radio']:checked ~ label::before {
    @apply content-['\f192'] {} }

.form-group {
  @apply relative {} }
  .form-group > label {
    @apply relative block text-base font-normal leading-normal text-neutral-800 {} }
  .form-group [class*='fa-ex'] {
    @apply text-[12px] text-red-700 italic block mt-1 font-sans font-normal {} }

.modal {
  @apply bg-transparent bg-none p-0 hidden {} }
  .modal .modal-wrap {
    @apply bg-white p-5 rounded-2 shadow xl:p-10 {} }

.modal-md {
  @apply rem:max-w-[768px] {} }

.modal-lg {
  @apply rem:max-w-[1024px] {} }

.modal-xl {
  @apply rem:max-w-[1280px] {} }

.pagination {
  @apply flex-center flex-wrap gap-3 mt-8 {} }
  .pagination span, .pagination a {
    @apply body-16 font-bold text-primary-900 border border-primary-900 transition-all flex-center w-11 h-11 hover:text-white hover:bg-primary-900 {} }
  .pagination li.active span, .pagination li.active a {
    @apply text-white bg-primary-900 {} }

.custom-scroll-dark::-webkit-scrollbar {
  width: 14px;
  height: 14px; }

.custom-scroll-dark::-webkit-scrollbar-button {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-track {
  background-color: #646464 !important; }

.custom-scroll-dark::-webkit-scrollbar-track-piece {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #242424 !important;
  border: 2px solid #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:start:decrement {
  background: linear-gradient(130deg, #696969 40%, rgba(255, 0, 0, 0) 41%), linear-gradient(230deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(0deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:end:increment {
  background: linear-gradient(310deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(50deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(180deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:end:increment {
  background: linear-gradient(210deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(330deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(90deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:start:decrement {
  background: linear-gradient(30deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(150deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(270deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.swiper-pagination .swiper-pagination-bullet {
  @apply opacity-100 rem:w-[10px] rem:h-[15px] rounded-none bg-transparent text-primary-800/50 flex items-end {} }
  .swiper-pagination .swiper-pagination-bullet::before {
    content: '';
    @apply w-full rem:h-[10px] bg-current transition-all {} }
  .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply text-primary-800 {}    @apply before:h-full {} }

.swiper-pagination.is-white .swiper-pagination-bullet {
  @apply text-white/50 {} }
  .swiper-pagination.is-white .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply text-white {} }

.swiper-pagination.is-abs {
  @apply absolute bottom-3 left-0 z-1 w-full flex-center md:bottom-10 {} }

.swiper-button > * {
  @apply text-2xl text-primary-900 w-12 h-12 rounded-1 border border-primary-900 bg-transparent flex-center transition-all cursor-pointer hover:text-white hover:bg-primary-900 {} }
  .swiper-button > *.swiper-button-disabled {
    @apply opacity-40 {} }
  .swiper-button > *.swiper-button-lock {
    @apply opacity-0 pointer-events-none {} }

.swiper-button.is-white > * {
  @apply text-white bg-white/20 border-white hover:text-neutral-200 {} }

.swiper-button.is-abs > * {
  @apply absolute top-1/2 -translate-y-1/2 z-1 {} }

.swiper-button.is-abs .button-prev {
  @apply left-1 xl:-left-20 {} }

.swiper-button.is-abs .button-next {
  @apply right-1 xl:-right-20 {} }

.swiper-button.is-abs.is-top-20 > * {
  @apply top-[20%] {} }

.swiper-button.is-abs.is-top-30 > * {
  @apply top-[30%] {} }

.swiper-button.is-abs.is-top-40 > * {
  @apply top-[40%] {} }

.swiper-button.is-abs.is-top-45 > * {
  @apply top-[45%] {} }

.swiper-relative {
  @apply lg:px-10 xl:px-0 {} }
  .swiper-relative .swiper-button.is-absolute .button-prev {
    @apply left-0 xl:-left-12 2xl:-left-22 {} }
  .swiper-relative .swiper-button.is-absolute .button-next {
    @apply right-0 xl:-right-12 2xl:-right-22 {} }
  .swiper-relative .swiper-scrollbar {
    @apply h-[2px] bg-neutral-300 {} }
    .swiper-relative .swiper-scrollbar .swiper-scrollbar-drag {
      @apply bg-primary-700 {} }

.about-item {
  @apply bg-white p-5 space-y-2 xl:p-8 {} }
  .about-item .icon {
    @apply w-24 h-24 flex-center {} }
  .about-item .number {
    @apply header-40 {}    @apply font-bold text-primary-900 leading-[calc(48/40)] {} }
  .about-item .caption {
    @apply body-14 font-medium text-black {} }

.line-b-primary {
  @apply pb-9 border-b-4 border-primary-900 {} }

.box-slogan {
  @apply w-max mx-auto p-5 bg-primary-900 section-header-32 font-bold text-white uppercase text-center xl:px-8 {} }

.university-name {
  @apply sub-header-20 font-bold text-black {} }
  .university-name .highlight {
    @apply text-primary-900 {} }

.about-1 .image-content {
  @apply py-10 xl:py-16 {} }

.about-1 .about-list {
  @apply space-y-5 xl:space-y-14 {} }

.about-1 .about-item {
  @apply p-0 {} }

.about-1 .full-content {
  @apply rem:max-h-[468px] overflow-y-auto pr-8 {} }
  .about-1 .full-content::-webkit-scrollbar {
    @apply w-1 {} }
  .about-1 .full-content::-webkit-scrollbar-track {
    @apply bg-neutral-100 {} }
  .about-1 .full-content::-webkit-scrollbar-thumb {
    @apply bg-primary-900 {} }
  .about-1 .full-content::-webkit-scrollbar-thumb:hover {
    @apply bg-primary-950 {} }
  .about-1 .full-content ul {
    @apply pl-5 {} }
  .about-1 .full-content li {
    @apply marker:text-black {} }

.about-3-item {
  @apply p-5 bg-neutral-50 flex flex-col justify-center gap-4 xl:p-8 xl:rem:min-h-[224px] {} }

.vision-mission-item {
  @apply bg-neutral-50 h-full {} }
  .vision-mission-item .image {
    @apply aspect-[736/414] {} }
  .vision-mission-item .caption {
    @apply p-5 flex flex-col gap-4 xl:p-8 {} }
  .vision-mission-item .title {
    @apply sub-header-24 font-bold text-primary-900 {} }
  .vision-mission-item .brief {
    @apply body-16 font-normal text-black {} }

.about-3 .image-content {
  @apply py-10 xl:py-16 {} }

.about-4 {
  @apply rem:border-b-[34px] border-primary-900 {} }
  .about-4 .full-content {
    @apply py-6 border-t border-secondary-light-gray {} }
    .about-4 .full-content hr {
      @apply my-6 border-secondary-light-gray {} }
    .about-4 .full-content h3 {
      @apply sub-header-20 font-bold mb-3 {} }
  .about-4 .box-content {
    @apply xl:pb-16 xl:rem:max-w-[640px] {} }
  .about-4 .image {
    @apply -mb-2 {} }
  .about-4.is-bg-leaner {
    background: linear-gradient(180deg, rgba(238, 245, 246, 0) 0%, #E4E4E4 100%); }

.about-5 .image {
  @apply mt-10 xl:mt-16 {} }

.count-up-item {
  @apply flex flex-col justify-center items-center body-16 font-bold text-black text-center {} }
  .count-up-item .count-up {
    @apply header-48 text-primary-900 {} }

.count-up-list {
  @apply p-5 grid grid-cols-2 gap-5 border-b border-primary-900 justify-center xl:p-8 xl:flex xl:justify-center xl:gap-10 {} }
  .count-up-list > * {
    @apply xl:flex-1 {} }

.about-6-item .image {
  @apply aspect-[736/490] {} }

.about-6-item .caption {
  @apply p-5 xl:px-6 xl:py-8 {} }

.about-6-item .title {
  @apply sub-header-20 font-bold text-black {} }

.about-6 .full-content {
  @apply mt-10 xl:mt-16 {} }

.about-6 .swiper-relative {
  @apply mt-10 xl:mt-16 {} }

.scientist-item {
  @apply sub-header-20 font-medium text-black flex items-center justify-between gap-3 p-3 rounded-2 bg-white transition-all hover:bg-neutral-50 xl:px-8 {} }
  .scientist-item .arrow {
    @apply flex-center w-11 h-11 rounded-1 text-lg text-white bg-primary-900 {} }

.scientist-list {
  @apply grid grid-cols-1 rem:gap-[14px] md:grid-cols-2 md:gap-x-8 {} }

.about-7 .scientist-list {
  @apply mt-10 xl:mt-16 {} }

.year-slide {
  @apply cursor-pointer {} }
  .year-slide .dot {
    @apply w-7 h-7 rounded-full bg-transparent border border-transparent transition-all mx-auto flex-center relative {} }
    .year-slide .dot::before {
      content: '';
      @apply w-4 h-4 bg-white border border-primary-900 rounded-full transition-all {} }
  .year-slide .year {
    @apply text-center sub-header-20 font-bold text-neutral-700 mt-3 transition-all {} }
  .year-slide.swiper-slide-thumb-active .dot {
    @apply border-primary-900 bg-white {} }
    .year-slide.swiper-slide-thumb-active .dot::before {
      @apply bg-primary-900 {} }
  .year-slide.swiper-slide-thumb-active .year {
    @apply scale-120 text-primary-900 {} }

.history-top {
  @apply relative {} }
  .history-top::before {
    content: '';
    @apply absolute rem:top-[10px] left-0 w-full h-[1px] bg-neutral-200 pointer-events-none {} }

.leader-top {
  @apply relative {} }
  .leader-top::before {
    content: '';
    @apply absolute rem:top-[10px] left-0 w-full h-[1px] bg-neutral-200 pointer-events-none {} }

.history-item .image {
  @apply aspect-[992/560] {} }

.history-item .caption {
  @apply flex flex-col justify-center rem:gap-[22px] p-5 bg-white xl:p-10 xl:pl-14 {} }

.history-item .year {
  @apply sub-header-20 font-bold text-white bg-primary-900 px-5 w-max rem:py-[10px] {} }

.history-item .brief {
  @apply body-16 font-normal text-black {} }

@screen xl {
  .history-item {
    @apply flex {} }
    .history-item .image {
      @apply w-[calc(992/1504*100%)] {} }
    .history-item .caption {
      @apply flex-1 {} } }

.leader-item .image {
  @apply aspect-[302/384] {} }

.leader-item .caption {
  @apply body-14 font-medium text-neutral-700 p-5 text-center flex flex-col justify-between items-center rem:gap-[7px] {} }

.leader-item .name {
  @apply body-14 font-bold text-primary-900 {} }

.about-8 + .about-9 {
  @apply pt-0 {} }

.about-8 .box-history {
  @apply mt-8 {} }

.about-9 .leader-bot > .swiper {
  @apply xl:-mx-20 xl:px-20 {} }
  .about-9 .leader-bot > .swiper > .swiper-wrapper > .swiper-slide {
    @apply opacity-0 pointer-events-none {} }
    .about-9 .leader-bot > .swiper > .swiper-wrapper > .swiper-slide.swiper-slide-active {
      @apply opacity-100 pointer-events-auto {} }

.about-10 .image {
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.08); }

.about-10 .image-content {
  @apply mt-10 xl:mt-16 {} }

.about-11 .main-tabslet {
  @apply mt-10 xl:mt-16 {} }

.about-11 .card-item .caption {
  @apply bg-white {} }

.table-responsive table {
  @apply w-full {} }

.table-responsive th {
  @apply p-5 bg-primary-900 sub-header-20 font-bold text-white border border-primary-blue-100 {} }

.table-responsive td {
  @apply px-5 py-3 body-16 font-normal text-black border border-primary-blue-100 transition-all first:font-bold {} }

.table-responsive a {
  @apply body-16 font-normal text-primary-700 hover-underline hover:text-primary-900 {} }

.table-responsive tbody tr {
  @apply bg-white even:bg-neutral-50 {} }
  .table-responsive tbody tr:hover td {
    @apply text-primary-900 {} }
    .table-responsive tbody tr:hover td:not(:first-child) {
      text-shadow: 0.7px 0px 0 theme("colors.primary.900"); }
      .table-responsive tbody tr:hover td:not(:first-child) a {
        text-shadow: none; }

.about-table .tabslet-tabs {
  @apply flex-center flex-wrap gap-x-5 gap-y-3 {} }
  .about-table .tabslet-tabs .btn-solid {
    background-size: 0;
    @apply hover:text-white {} }
  .about-table .tabslet-tabs li {
    @apply border-none p-0 {} }
    .about-table .tabslet-tabs li.active .btn-solid {
      @apply bg-primary-900 text-white {} }

.assurance-detail-title {
  @apply leading-[calc(48/40)] {} }

.assurance-detail-section .news-date {
  @apply rem:mt-[22px] {} }

.assurance-detail-section .brief-content {
  @apply body-16 font-normal text-black rem:mt-[22px] pt-5 border-t border-neutral-gray-d9 xl:pt-8 {} }

.assurance-detail-section .full-content {
  @apply pt-10 rem:max-w-[1248px] mx-auto xl:pt-15 {} }

.global-breadcrumb {
  @apply bg-neutral-50 {} }
  .global-breadcrumb .breadcrumb {
    @apply flex flex-wrap items-center py-3 gap-x-3 gap-y-2 {} }
    .global-breadcrumb .breadcrumb a {
      @apply body-14 font-normal text-neutral-500 leading-normal {} }
    .global-breadcrumb .breadcrumb li {
      @apply flex items-center last:after:hidden {} }
      .global-breadcrumb .breadcrumb li::after {
        content: '';
        @apply ml-3 w-px h-6 bg-neutral-200 {} }
      .global-breadcrumb .breadcrumb li:first-child a {
        @apply text-0 {} }
        .global-breadcrumb .breadcrumb li:first-child a span {
          @apply hidden {} }
        .global-breadcrumb .breadcrumb li:first-child a::before {
          @apply font-awesome content-['\f015'] text-neutral-500 text-base font-normal leading-none flex-center {} }

.academic-detail-section .academic-list {
  @apply grid grid-cols-1 gap-8 sm:grid-cols-2 xl:gap-y-9 {} }

.academic-detail-section .academic-item .image {
  @apply aspect-[544/306] {} }

.academic-detail-section .academic-item .caption {
  @apply xl:px-8 {} }

.academic-detail-section .academic-item .title {
  @apply xl:text-xl {} }

.courses-item {
  @apply flex flex-col gap-3 py-5 border-b border-neutral-200 first:pt-0 {} }
  .courses-item .title {
    @apply body-18 font-bold text-black {} }
  .courses-item .brief {
    @apply body-16 font-normal text-black {} }

.site-nav {
  @apply flex flex-wrap gap-5 justify-center {} }
  .site-nav a {
    @apply flex-center h-11 rounded-full px-5 py-2 body-16 font-medium text-primary-900 border border-primary-900 bg-white hover:bg-primary-900 hover:text-white {} }
  .site-nav li.active a {
    @apply bg-primary-900 text-white {} }

.academic-item {
  @apply bg-neutral-50 rounded-b-4 overflow-hidden {} }
  .academic-item .image {
    @apply aspect-[480/322] img-zoom {} }
  .academic-item .caption {
    @apply p-5 xl:p-6 {} }
  .academic-item .title {
    @apply body-18 font-bold text-black hover:text-primary-900 {} }
  .academic-item:hover .image img {
    @apply scale-110 {} }
  .academic-item:hover .title {
    @apply text-primary-900 {} }

.academic-section .academic-list {
  @apply grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 {} }

.academic-nav {
  @apply flex items-center {} }

.box-categories {
  @apply rounded-4 bg-white shadow-light p-5 xl:p-6 xl:pb-2 {} }
  .box-categories .box-head {
    @apply pb-4 border-b border-neutral-gray-d9 {} }
  .box-categories .category-title {
    @apply flex justify-between gap-3 body-18 font-bold text-neutral-700 py-4 hover:text-primary-900 {} }
    .box-categories .category-title .category-toggle {
      @apply text-xl text-neutral-500 cursor-pointer {} }
  .box-categories .category-list > li {
    @apply border-b border-neutral-gray-d9 last:border-none {} }
    .box-categories .category-list > li.active {
      @apply border-transparent {} }
      .box-categories .category-list > li.active .category-title {
        @apply text-primary-950 {} }
        .box-categories .category-list > li.active .category-title .category-toggle {
          @apply text-current before:content-['\f068'] {} }
  .box-categories .sub-category {
    @apply mt-4 rounded-4 p-5 space-y-3 bg-neutral-50 hidden xl:p-6 {} }
    .box-categories .sub-category a {
      @apply body-16 font-normal text-black hover:text-primary-950 {} }
    .box-categories .sub-category li.active a {
      text-shadow: 0.7px 0 0 theme("colors.primary.950");
      @apply text-primary-950 {} }

.course-content {
  @apply body-16 font-normal text-black {} }
  .course-content > * + * {
    @apply mt-4 {} }

.box-course-content {
  @apply prose max-w-none {}  @apply body-16 font-normal text-black p-5 bg-neutral-50 xl:p-8 {} }
  .box-course-content h3 {
    @apply sub-header-24 font-bold text-primary-900 mb-3 mt-0 {} }
  .box-course-content ul {
    @apply pl-5 {} }
    .box-course-content ul li {
      @apply marker:text-primary-900 {} }

.home-banner {
  @apply relative {} }
  .home-banner .image {
    @apply aspect-[1920/1080] md:aspect-[1920/640] {} }

.contact-item {
  @apply bg-neutral-50 border-t-4 border-primary-900 p-5 md:p-10 xl:p-12 {} }
  .contact-item .icon {
    @apply text-32 text-black mb-3 {} }
  .contact-item .title {
    @apply body-16 font-bold text-black uppercase {} }
  .contact-item .line {
    @apply w-20 mx-auto h-px bg-neutral-300 my-4 {} }
  .contact-item .content {
    @apply body-16 font-normal text-black {} }
    .contact-item .content > * {
      @apply mb-2 last:mb-0 {} }
    .contact-item .content a {
      @apply text-primary-500 underline hover:text-primary-700 {} }

.contact-list {
  @apply grid grid-cols-1 md:grid-cols-2 gap-8 lg:grid-cols-3 {} }

.contact-form .txt {
  @apply text-base text-neutral-090909 {} }
  .contact-form .txt * {
    @apply text-base text-neutral-090909 {} }
  .contact-form .txt span {
    @apply text-primary-900 {} }
    .contact-form .txt span * {
      @apply text-primary-900 {} }

.contact-form .wrap-form .form-group input[type='text'], .contact-form .wrap-form .form-group input[type='email'], .contact-form .wrap-form .form-group input[type='tel'], .contact-form .wrap-form .form-group textarea, .contact-form .wrap-form .form-group select {
  @apply bg-neutral-50 border-transparent rounded-none h-[44px] px-5 text-neutral-950 {} }
  .contact-form .wrap-form .form-group input[type='text']::placeholder, .contact-form .wrap-form .form-group input[type='email']::placeholder, .contact-form .wrap-form .form-group input[type='tel']::placeholder, .contact-form .wrap-form .form-group textarea::placeholder, .contact-form .wrap-form .form-group select::placeholder {
    @apply text-neutral-700 font-normal text-base {} }

.contact-form .wrap-form .form-group textarea {
  height: 120px; }

.contact-form .wrap-form .frm-btnwrap button {
  @apply bg-primary-900 border border-primary-900 rounded-1 overflow-hidden h-[44px] w-[160px] px-4 py-2 transition {} }
  .contact-form .wrap-form .frm-btnwrap button span, .contact-form .wrap-form .frm-btnwrap button em {
    @apply leading-none text-base text-white transition {} }
  .contact-form .wrap-form .frm-btnwrap button span {
    @apply font-medium uppercase {} }
  .contact-form .wrap-form .frm-btnwrap button em {
    @apply ml-2 {} }
  .contact-form .wrap-form .frm-btnwrap button:hover {
    @apply bg-white {} }
    .contact-form .wrap-form .frm-btnwrap button:hover span, .contact-form .wrap-form .frm-btnwrap button:hover em {
      @apply text-primary-900 {} }

.contact-form .block-wrap {
  @apply flex flex-col justify-center h-full {} }

@media screen and (max-width: 1024px) {
  .contact-form .img {
    @apply pt-8 {} } }

.contact-form .img a {
  @apply flex aspect-[1/1] {} }

.document-section .table-responsive th {
  @apply text-left first:w-20 {} }

.document-section .table-responsive a {
  @apply text-black hover:text-primary-900 {} }

.document-section .table-responsive th, .document-section .table-responsive td {
  @apply first:text-center {} }

.document-section .table-responsive td:last-child {
  @apply rem:w-[244px] {} }
  .document-section .table-responsive td:last-child a {
    @apply mx-auto flex-center w-max text-primary-700 hover:text-primary-900 {} }

.nav-list {
  @apply flex items-center gap-1 p-1 overflow-x-auto overflow-y-hidden {} }
  .nav-list .btn-solid {
    @apply text-sm {} }
    .nav-list .btn-solid i, .nav-list .btn-solid em {
      @apply text-xl {} }
  .nav-list li {
    @apply w-max {} }
    .nav-list li a {
      @apply whitespace-nowrap {} }

.home-nav {
  @apply sticky top-22 z-10 md:top-27 xl:top-30 {} }
  .home-nav .nav-list {
    @apply xl:justify-center {} }
  @media (max-width: 1279.98px) {
    .home-nav .nav-list {
      align-items: normal;
      @apply overflow-visible {} }
      .home-nav .nav-list li {
        @apply flex-1 h-auto {} }
        .home-nav .nav-list li a {
          @apply whitespace-normal flex-col text-center h-full justify-start p-3 {} } }
  @media (max-width: 575.98px) {
    .home-nav {
      @apply static {} }
      .home-nav .nav-list {
        @apply flex-wrap {} }
        .home-nav .nav-list li {
          @apply basis-1/4 {} } }

.hub-title {
  @apply space-y-7 xl:space-y-12 {} }
  .hub-title .university-name {
    letter-spacing: 6.4px;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: rgba(255, 224, 195, 0.88);
    @apply rem:text-[40px] font-extrabold text-[#FFE0C3]/88 uppercase leading-[calc(90/80)] xl:rem:text-[80px] {} }
    .hub-title .university-name .highlight {
      @apply text-white {} }
  .hub-title h2 {
    @apply rem:text-[30px] font-bold text-white xl:rem:text-[48px] {} }

.home-about {
  @apply xl:pt-20 xl:pb-33 {} }
  .home-about .block-about {
    @apply space-y-7 xl:space-y-12 {} }
  .home-about .about-list {
    @apply columns-1 gap-8 break-inside-avoid md:columns-2 {} }
  .home-about .about-item {
    @apply mb-8 lg:first:mt-15 xl:rem:min-h-[304px] {} }

.home-news:is(.news-list-section) .news-list {
  @apply grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-12 {} }

@screen xl {
  .home-news:is(.news-list-section) .news-item {
    @apply col-span-4 {} }
    .home-news:is(.news-list-section) .news-item:nth-child(1), .home-news:is(.news-list-section) .news-item:nth-child(2) {
      @apply col-span-6 rounded-none {} } }

.home-courses-item {
  @apply bg-white rounded-b-4 overflow-hidden relative shadow-1 {} }
  .home-courses-item .image {
    @apply aspect-[352/234] img-zoom {} }
  .home-courses-item .caption {
    @apply p-5 xl:p-6 {} }
  .home-courses-item .title {
    @apply body-18 font-bold text-black transition-all text-center uppercase {} }
  .home-courses-item:hover .title {
    @apply text-primary-900 {} }

.home-courses-list {
  @apply grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 {} }

.home-courses .site-desc {
  @apply rem:max-w-[956px] {} }

.home-courses .count-up-list {
  @apply border-y border-neutral-400 {} }

.home-committed .home-courses-item .caption {
  @apply px-3 {} }

.story-item .image {
  @apply aspect-[864/448] rounded-4 {} }

.story-item .caption {
  @apply bg-white p-5 flex flex-col rounded-4 border-b-2 border-neutral-200 md:p-10 xl:px-16 xl:py-14 {} }

.story-item .name {
  @apply sub-header-20 font-bold text-primary-900 {} }

.story-item .sub-name {
  @apply body-14 font-medium text-neutral-500 {} }

.story-item .line-dot {
  @apply flex items-center rem:gap-[2px] {} }
  .story-item .line-dot .dot {
    @apply w-1 h-1 bg-primary-500 even:bg-primary-900 {} }

.story-item .brief {
  @apply body-14 font-medium text-black {} }

@screen md {
  .story-item {
    @apply flex flex-row-reverse {} }
    .story-item > * {
      @apply w-1/2 {} }
    .story-item .caption {
      @apply border-b-0 border-l-2 {} } }

@screen xl {
  .story-item .image {
    @apply rem:w-[864px] flex-none {} }
  .story-item .caption {
    @apply flex-1 {} } }

.home-story .swiper-relative {
  @apply pb-10 {} }

.home-story .swiper-pagination {
  @apply bottom-0 {} }

@screen xl {
  .home-story .swiper {
    @apply overflow-visible rem:max-w-[1312px] {} } }

.unit-link-list {
  @apply flex-center flex-wrap gap-5 {} }
  .unit-link-list li {
    @apply pr-5 border-r border-neutral-400 last:pr-0 last:border-none {} }
  .unit-link-list a {
    @apply block text-center sub-header-20 font-normal text-black leading-[1.4] hover-underline hover:text-primary-900 {} }

.home-our-research .site-desc {
  @apply rem:max-w-[809px] {} }

.home-our-research .count-up-list {
  @apply border-y border-neutral-400 {} }

.home-connectivity .site-desc {
  @apply rem:max-w-[849px] {} }

.home-connectivity .count-up-list {
  @apply border-y border-neutral-400 {} }

@screen xl {
  .home-connectivity .swiper {
    @apply -mx-2 px-2 pb-2 {} }
  .home-connectivity .home-courses-item {
    @apply shadow {} } }

.home-hub-life {
  @apply relative md:rem:min-h-[560px] {} }
  .home-hub-life .box-content {
    @apply relative z-2 flex flex-col gap-5 py-10 md:py-19 md:rem:max-w-[484px] {} }
  .home-hub-life .button {
    @apply flex-col gap-3 {} }
  .home-hub-life .btn-solid {
    @apply w-max xl:px-6 {} }

@screen md {
  .home-hub-life .image {
    @apply absolute inset-0 z-1 {} } }

header {
  box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.05);
  @apply sticky top-0 left-0 right-0 z-999 bg-white {} }
  header .header-top {
    @apply py-3 transition-all duration-300 ease-in-out xl:rem:pt-[15px] {} }
    header .header-top .header-right {
      @apply flex items-center justify-end gap-2 {} }
  header .header-bot {
    @apply hidden xl:block {} }
  header .logo {
    @apply rem:h-[85px] transition-all duration-300 ease-in-out {} }
    header .logo img, header .logo svg {
      @apply w-full h-full object-contain {} }
  header .button-contact + .button-language {
    @apply relative flex items-center {} }
    header .button-contact + .button-language::before {
      content: '';
      @apply pl-2 border-l border-neutral-100 h-4 {} }
  header .contact-list {
    @apply flex items-center gap-2 {} }
    header .contact-list a {
      @apply body-14 font-medium text-secondary-200 flex-center gap-2 px-2 rounded-1 rem:py-[6px] hover:text-primary-900 hover:bg-primary-50 {} }
      header .contact-list a i {
        @apply text-primary-900 {} }
    header .contact-list li {
      @apply leading-none {} }
      header .contact-list li + li {
        @apply relative flex items-center {} }
        header .contact-list li + li::before {
          content: '';
          @apply pl-2 border-l border-neutral-100 h-4 {} }
  header .language-list {
    @apply flex items-center gap-2 px-2 rem:py-[6px] {} }
    header .language-list a {
      @apply body-14 font-medium text-secondary-200 flex-center hover:text-primary-900 {} }
    header .language-list li {
      @apply leading-none {} }
      header .language-list li + li {
        @apply relative flex items-center {} }
        header .language-list li + li::before {
          content: '';
          @apply pl-2 border-l border-neutral-100 h-4 {} }
      header .language-list li.active a {
        text-shadow: 0.75px 0 0 theme("colors.primary.900");
        @apply text-primary-900 {} }
  header .button-search {
    @apply bg-primary-900 flex-center w-17 h-11 rounded-1 text-white text-xl cursor-pointer transition-all hover:bg-primary-950 {} }
  header .menu-top {
    @apply hidden items-center gap-2 xl:flex {} }
    header .menu-top > li > a {
      @apply body-14 font-medium text-primary-900 flex-center p-3 h-11 bg-neutral-50 rounded-1 hover:bg-primary-50 {} }
    header .menu-top > li.has-dropdown {
      @apply relative {} }
      header .menu-top > li.has-dropdown:hover > a {
        @apply bg-primary-900 text-white {} }
      header .menu-top > li.has-dropdown:hover .dropdown-menu {
        @apply opacity-100 pointer-events-auto {} }
    header .menu-top .dropdown-menu {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
      backdrop-filter: blur(8px);
      @apply absolute z-1 top-full left-0 w-max opacity-0 pointer-events-none transition-all duration-200 ease-in-out {} }
      header .menu-top .dropdown-menu a {
        @apply body-16 font-medium text-neutral-950 flex text-left py-3 px-8 h-12 bg-white border-b border-neutral-200 whitespace-nowrap hover:text-primary-900 hover:border-primary-900 {} }
  header .main-menu {
    @apply flex justify-between items-center {} }
    header .main-menu .dot {
      @apply w-1 h-1 bg-white flex-center {} }
    header .main-menu > li > a {
      @apply body-16 font-bold text-white block text-center py-3 hover-underline {} }
    header .main-menu > li.active a {
      @apply hover-underline-active {} }
    header .main-menu > li.has-mega-menu:hover {
      @apply before:opacity-100 {} }
      header .main-menu > li.has-mega-menu:hover > a {
        @apply hover-underline-active {} }
      header .main-menu > li.has-mega-menu:hover .mega-menu {
        @apply opacity-100 pointer-events-auto {} }
  header .menu-toggle {
    @apply hidden {} }
  header .mega-menu {
    @apply absolute -z-1 top-full left-0 w-full bg-white shadow flex justify-center max-h-[80vh] overflow-y-auto opacity-0 pointer-events-none transition-opacity duration-200 ease-in-out {} }
  header .mega-list {
    @apply flex flex-wrap -mx-4 -mt-12 pt-8 pb-11 {} }
    header .mega-list > * {
      @apply w-1/4 px-4 mt-12 {} }
    header .mega-list > li > a {
      @apply body-16 font-bold text-primary-900 uppercase block pb-3 border-b border-primary-900 hover:text-primary-950 hover:border-current {} }
  header .children-menu > li > a {
    @apply block py-3 body-14 font-medium text-neutral-950 border-b border-neutral-200 hover:text-primary-900 hover:border-current {} }
  @media (max-width: 1023.98px) {
    header .contact-list a span {
      @apply hidden {} } }
  @media (max-width: 575.98px) {
    header .logo {
      @apply h-16 {} }
    header .button-search {
      @apply w-11 {} } }

@screen xl {
  header.active .header-top {
    @apply py-2 {} }
  header.active .logo {
    @apply h-15 {} } }

.mobile-wrap {
  @apply fixed top-0 w-screen h-screen bg-white shadow z-998 pt-30 pl-4 pb-4 max-w-md -left-full opacity-0 transition-all duration-500 ease-in-out pointer-events-none xl:hidden {} }
  .mobile-wrap .navbar-nav-list {
    @apply pr-4 max-h-[calc(100vh-120px)] overflow-y-auto {} }
  .mobile-wrap .main-menu {
    @apply space-y-3 {} }
    .mobile-wrap .main-menu li {
      @apply relative {} }
    .mobile-wrap .main-menu > li > a {
      @apply text-[16px] font-bold text-neutral-950 block p-3 bg-neutral-50 rounded-1 {} }
    .mobile-wrap .main-menu > li.active > a {
      @apply hover-underline-active text-primary-950 bg-primary-900/10 {} }
    .mobile-wrap .main-menu > li.active > .menu-toggle {
      @apply text-primary-900 {} }
  .mobile-wrap .menu-toggle {
    @apply absolute top-0 right-0 w-11 h-11 flex-center text-neutral-950 cursor-pointer transition-all {} }
    .mobile-wrap .menu-toggle.active {
      @apply rotate-180 text-primary-900 {} }
  .mobile-wrap .mega-menu {
    @apply hidden pb-5 {} }
  .mobile-wrap .mega-list {
    @apply py-3 px-4 bg-neutral-50 rounded-1 space-y-3 mt-2 -mr-4 {} }
    .mobile-wrap .mega-list > li > a {
      @apply text-[16px] font-bold text-neutral-950 block py-2 {} }
    .mobile-wrap .mega-list > li.active > a {
      @apply text-primary-950 {} }
  .mobile-wrap .children-menu {
    @apply pl-7 {} }
    .mobile-wrap .children-menu > li > a {
      @apply text-[15px] font-medium text-neutral-950 block py-2 {} }
    .mobile-wrap .children-menu > li.active > a {
      @apply text-primary-950 {} }
  .mobile-wrap .menu-top {
    @apply flex flex-col gap-2 mt-2 {} }
    .mobile-wrap .menu-top > li > a {
      @apply text-[16px] font-bold text-black flex text-left p-3 h-12 bg-neutral-50 rounded-1 {} }
    .mobile-wrap .menu-top > li.has-dropdown {
      @apply relative {} }
    .mobile-wrap .menu-top .dropdown-menu {
      @apply mb-2 pl-5 hidden {} }
      .mobile-wrap .menu-top .dropdown-menu a {
        @apply body-14 font-medium text-neutral-950 flex text-left py-3 px-8 h-12 bg-white border-b border-neutral-200 whitespace-nowrap hover:text-primary-900 hover:border-primary-900 {} }
  .mobile-wrap .contact-list {
    @apply flex flex-col gap-2 mt-2 {} }
    .mobile-wrap .contact-list a {
      @apply body-16 font-medium text-primary-900 flex items-center gap-2 p-3 h-11 bg-neutral-50 rounded-1 {} }
      .mobile-wrap .contact-list a span {
        @apply text-secondary-200 {} }
  .mobile-wrap.open {
    @apply left-0 opacity-100 pointer-events-auto {} }

.search-wrap {
  @apply fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 max-w-[1000] mx-auto z-[2000] flex-center opacity-0 transition-all duration-300 ease-in-out pointer-events-none px-4 w-full {} }
  .search-wrap .searchbox {
    @apply w-full relative {} }
    .search-wrap .searchbox input {
      @apply text-gray-700 {} }
    .search-wrap .searchbox button {
      @apply absolute top-1/2 right-0 -translate-y-1/2 w-11 h-11 flex-center text-neutral-950 cursor-pointer transition-all {} }
  .search-wrap.open {
    @apply opacity-100 pointer-events-auto {} }

.faq-list {
  counter-reset: section; }
  .faq-list .toggle-item {
    @apply overflow-hidden mb-5 {} }
    .faq-list .toggle-item.is-toggle {
      box-shadow: 2px 2px 12px 2px #00000014; }
      .faq-list .toggle-item.is-toggle .title {
        @apply border-primary-900 {} }
        .faq-list .toggle-item.is-toggle .title .number {
          @apply bg-primary-900 text-white {} }
        .faq-list .toggle-item.is-toggle .title em {
          transform: rotateX(180deg);
          @apply text-primary-900 {} }
      .faq-list .toggle-item.is-toggle .article {
        @apply block {} }
    .faq-list .toggle-item .title {
      @apply flex justify-start items-center gap-5 bg-neutral-50 rounded-1 overflow-hidden border-b border-neutral-50 cursor-pointer transition-all {} }
    .faq-list .toggle-item .number {
      @apply body-18 font-bold text-neutral-500 flex-center w-15 h-15 rounded-1 bg-neutral-200 transition-all {} }
    .faq-list .toggle-item .txt {
      @apply flex-1 flex justify-start items-center transition py-2 relative bg-neutral-50 pr-10 rounded {} }

@screen lg {
  .faq-list .toggle-item .txt {
    @apply w-full m-0 {} } }
      .faq-list .toggle-item .txt > span {
        @apply font-bold text-lg {} }
        .faq-list .toggle-item .txt > span * {
          @apply font-bold text-lg {} }
      .faq-list .toggle-item .txt em {
        @apply ml-5 inline-block absolute top-1/2 right-[20px] {}        transform: translateY(-50%);
        font-size: 18px;
        height: 15px;
        width: 16px;
        transition: .3s all  ease-in-out; }
        @media screen and (max-width: 1023.98px) {
          .faq-list .toggle-item .txt em {
            font-size: 16px;
            height: 16px;
            width: 16px; } }

.toggle-item .article {
  @apply hidden  pt-3 font-normal text-neutral-090909 text-base  border-t border-t-transparent {}  padding: 1.25rem 1.25rem 0 1.25rem; }

@screen lg {
  .toggle-item .article {
    padding: 1.25rem 3.125rem; } }
  .toggle-item .article * {
    @apply font-normal text-neutral-090909 text-base {} }
  .toggle-item .article p {
    @apply block mb-4 {} }
  .toggle-item .article ul {
    @apply mt-4 {}    padding-left: 20px;
    list-style-type: disc; }
    .toggle-item .article ul li {
      @apply mb-4 {} }
  .toggle-item .article strong, .toggle-item .article h3, .toggle-item .article h4 {
    @apply font-bold {} }
  .toggle-item .article img {
    @apply block mx-auto my-4 {} }
  .toggle-item .article ul {
    list-style-type: disc; }
    .toggle-item .article ul li {
      @apply mb-2 {} }

.event-item {
  @apply flex flex-col bg-neutral-50 rounded-b-4 border-b-4 border-neutral-50 overflow-hidden relative {} }
  .event-item .image {
    @apply aspect-[480/322] img-zoom {} }
  .event-item .caption {
    @apply flex-1 flex flex-col gap-3 p-5 transition-all xl:p-6 xl:pb-8 {} }
  .event-item .news-date {
    @apply text-neutral-400 {} }
  .event-item .event-title {
    @apply body-18 font-bold text-black line-clamp-3 hover:text-primary-900 {} }
  .event-item .brief {
    @apply body-16 font-normal text-neutral-900 {} }
  .event-item:hover .image img {
    @apply scale-110 {} }
  .event-item:hover .caption {
    @apply border-primary-900 {} }

@screen md {
  .event-item.is-big {
    @apply flex-row rounded-none {} }
    .event-item.is-big .image {
      @apply w-[calc(992/1504*100%)] aspect-[992/660] {} }
    .event-item.is-big .caption {
      @apply justify-center p-8 gap-6 {} }
    .event-item.is-big .event-title {
      @apply sub-header-24 line-clamp-4 {} } }

@screen xl {
  .event-item.is-big .caption {
    @apply p-16 {} } }

.event-list {
  @apply grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 {} }

.event-list-section .event-item {
  @apply md:first:col-span-2 lg:first:col-span-3 {} }

.box-new-notification {
  @apply p-5 bg-neutral-50 xl:p-8 {} }
  .box-new-notification .notification-list {
    @apply mt-5 {} }
  .box-new-notification .notification-item {
    @apply flex-row gap-5 py-5 px-0 bg-transparent border-t border-neutral-200 first:border-none first:pt-0 last:pb-0 hover:bg-transparent {} }
    .box-new-notification .notification-item .news-title {
      @apply body-14 font-medium line-clamp-4 {} }
    .box-new-notification .notification-item:hover .news-title {
      @apply text-primary-900 {} }

.hub-40-section .news-list {
  @apply grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 {} }

.hub-life-item {
  @apply bg-neutral-50 text-neutral-50 cursor-pointer rounded-b-4 overflow-hidden {} }
  .hub-life-item .image {
    @apply aspect-[480/322] img-zoom {} }
  .hub-life-item .caption {
    @apply p-5 rounded-b-4 border-b-4 border-current transition-all overflow-hidden xl:px-6 xl:py-8 {} }
  .hub-life-item .title {
    @apply sub-header-20 font-bold text-black transition {} }
  .hub-life-item:hover {
    @apply text-primary-900 {} }
    .hub-life-item:hover .title {
      @apply text-current {} }
  .hub-life-item.is-video .image::before {
    @apply font-awesome content-['\f04b'] text-2xl font-normal text-white w-16 h-16 rounded-full flex-center bg-black/50 absolute-center z-2 pointer-events-none transition duration-200 ease-linear {} }
  .hub-life-item.is-video .image::after {
    content: '';
    @apply absolute-center w-full h-full bg-black/20 z-1 pointer-events-none transition-all {} }
  .hub-life-item.is-video .title {
    @apply body-18 {} }
  .hub-life-item.is-video:hover .image::before {
    @apply scale-120 opacity-0 {} }
  .hub-life-item.is-video:hover .image::after {
    @apply opacity-0 {} }

.gallery-list {
  @apply grid grid-cols-1 gap-8 md:grid-cols-2 {} }

@screen lg {
  .gallery-list {
    @apply grid-cols-6 {} }
    .gallery-list .hub-life-item {
      @apply col-span-2 {} }
      .gallery-list .hub-life-item:nth-child(1), .gallery-list .hub-life-item:nth-child(2) {
        @apply col-span-3 {} }
        .gallery-list .hub-life-item:nth-child(1) .image, .gallery-list .hub-life-item:nth-child(2) .image {
          @apply aspect-[736/490] {} } }

.video-list {
  @apply grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 {} }

.gallery-section .site-desc {
  @apply rem:max-w-[809px] {} }

.date-status-flex {
  @apply flex items-center gap-3 {} }

.status {
  @apply bg-primary-900 px-3 py-1 body-14 font-medium text-white {} }

.date-share {
  @apply flex items-center justify-between gap-4 {} }

.news-detail-section .news-detail-title {
  @apply leading-[calc(48/40)] {} }

.news-detail-section .news-date {
  @apply text-neutral-400 {} }

.news-detail-section .date-share {
  @apply mt-6 {} }
  .news-detail-section .date-share .date-status-flex {
    @apply m-0 {} }

.news-detail-section .date-status-flex {
  @apply mt-6 {} }
  .news-detail-section .date-status-flex .news-date {
    @apply mt-0 {} }

.news-detail-section .status {
  @apply text-white text-sm py-1 px-3 bg-primary-900 ml-3 {} }

.news-detail-section .full-content {
  @apply pt-6 mt-6 border-t border-neutral-gray-d9 {} }

.news-detail-section .bottom-wrap {
  @apply pb-5 border-b border-b-neutral-D9D9D9 flex justify-start items-center {} }

@screen xl {
  .news-detail-section .bottom-wrap {
    @apply pb-6 {} } }

.briefcontent {
  @apply font-bold leading-tight text-neutral-950 text-justify pt-4 mb-2 {} }
  .briefcontent * {
    @apply font-bold leading-tight text-neutral-950 text-justify {} }

@screen xl {
  .briefcontent {
    @apply pt-6 {} } }

.events-other-section:is(.bg-neutral-50) .event-item {
  @apply bg-white {} }

.news-item-big {
  @apply relative {} }
  .news-item-big::after {
    content: '';
    @apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full {}    background: linear-gradient(342.92deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 76.5%); }
  .news-item-big .img a {
    @apply flex aspect-[352/234] {} }
  .news-item-big .txt {
    @apply absolute bottom-0 left-0 right-0 z-20 p-6 pointer-events-none {} }

@screen xl {
  .news-item-big .txt {
    @apply p-8 {} } }
    .news-item-big .txt span {
      @apply sub-header-20 text-white font-bold {}      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden; }
  .news-item-big time {
    @apply text-neutral-200 font-medium mb-2 block {}    font-size: 12px; }
  .news-item-big:hover .txt span {
    @apply underline {} }

.news-list-section .news-list {
  @apply grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 {} }

.news-list-section .news-item {
  @apply bg-neutral-50 {} }

@screen xl {
  .news-list-section .news-item:nth-child(1), .news-list-section .news-item:nth-child(2) {
    @apply col-span-2 rounded-none {} }
    .news-list-section .news-item:nth-child(1) .image, .news-list-section .news-item:nth-child(2) .image {
      @apply aspect-[736/484] {} }
    .news-list-section .news-item:nth-child(1) .caption, .news-list-section .news-item:nth-child(2) .caption {
      background: linear-gradient(0, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 76.5%);
      @apply absolute bottom-0 left-0 z-1 w-full bg-transparent rounded-none border-0 p-8 rem:pt-[153px] gap-3 justify-end pointer-events-none {} }
      .news-list-section .news-item:nth-child(1) .caption > *, .news-list-section .news-item:nth-child(2) .caption > * {
        @apply pointer-events-auto {} }
    .news-list-section .news-item:nth-child(1) .news-date, .news-list-section .news-item:nth-child(2) .news-date {
      @apply text-neutral-200 {} }
    .news-list-section .news-item:nth-child(1) .news-title, .news-list-section .news-item:nth-child(2) .news-title {
      @apply text-white sub-header-20 {} } }

.box-news-detail {
  @apply rem:max-w-[1088px] {} }

.box-news-other .box-title {
  @apply pb-5 border-b border-neutral-200 {} }

.box-news-other .news-item {
  @apply flex-row items-center gap-4 py-5 border-b border-neutral-200 rounded-none {} }
  .box-news-other .news-item .image {
    @apply w-[112px] h-[76px] {} }
  .box-news-other .news-item .caption {
    background-size: 0;
    @apply flex-1 flex flex-col gap-1 p-0 rounded-none border-none {} }
  .box-news-other .news-item .news-date {
    @apply text-[12px] font-medium m-0 {} }
  .box-news-other .news-item .news-title {
    @apply body-14 font-medium text-black line-clamp-3 hover:text-primary-900 {} }

.news-date {
  @apply body-14 font-medium text-neutral-700 {} }

.card-item {
  @apply flex flex-col h-full {} }
  .card-item .image {
    @apply aspect-[352/236] img-zoom {} }
  .card-item .caption {
    @apply flex-1 flex flex-col gap-3 p-5 bg-neutral-50 border-b-4 border-neutral-50 rounded-b-4 transition-all xl:p-6 xl:pb-8 {} }
  .card-item .card-title {
    @apply body-16 font-bold text-black hover:text-primary-900 {} }
  .card-item:hover .caption {
    @apply border-primary-900 {} }

.news-item {
  @apply flex flex-col bg-white rounded-b-4 overflow-hidden relative {} }
  .news-item .image {
    @apply aspect-[480/322] img-zoom {} }
  .news-item .caption {
    @apply flex-1 flex flex-col gap-3 p-5 rounded-b-4 border-b-4 border-neutral-50 transition-all xl:p-6 xl:pb-8 {} }
  .news-item .news-title {
    @apply body-18 font-bold text-black line-clamp-3 hover:text-primary-900 {} }
  .news-item:hover .image img {
    @apply scale-110 {} }
  .news-item:hover .caption {
    @apply border-primary-900 {} }

.notification-detail-section {
  @apply leading-[calc(48/40)] {} }
  .notification-detail-section .news-date {
    @apply text-neutral-400 {} }
  .notification-detail-section .full-content iframe {
    @apply w-full aspect-[1088/1454] rem:max-h-[1454px] {} }

.notification-item {
  @apply flex flex-col gap-5 transition-all p-5 bg-neutral-50 xl:p-8 xl:gap-6 {} }
  .notification-item .date {
    @apply bg-primary-900 flex-center flex-col w-18 h-18 text-white transition-all {} }
  .notification-item .day {
    @apply sub-header-24 font-bold {} }
  .notification-item .month-year {
    @apply label-12 font-normal {} }
  .notification-item .caption {
    @apply flex-1 flex flex-col gap-8 xl:gap-15 {} }
  .notification-item .news-title {
    @apply body-16 font-bold text-neutral-950 line-clamp-3 transition-all {} }
  .notification-item .link {
    background-size: 0;
    @apply text-neutral-950 transition-all w-max {} }
  .notification-item:hover {
    @apply bg-primary-900 {} }
    .notification-item:hover .date {
      @apply bg-white text-primary-900 {} }
    .notification-item:hover .news-title {
      @apply text-white {} }
    .notification-item:hover .link {
      @apply text-white {} }

.notification-section .notification-list {
  @apply grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 {} }

.student-item {
  box-shadow: 0px 4px 20px 0px #0000001F;
  @apply rounded-b-4 overflow-hidden bg-white {} }
  .student-item .image {
    @apply aspect-[352/234] {} }
  .student-item .title {
    @apply body-18 font-bold text-black py-6 px-5 flex-center transition-all hover:text-primary-900 {} }
  .student-item:hover .title {
    @apply text-primary-900 {} }

.student-list {
  @apply grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 {} }

.footer-title {
  @apply py-2 body-18 font-bold text-white {} }

.footer-menu {
  @apply space-y-3 {} }
  .footer-menu a {
    @apply body-16 font-medium text-white hover-underline {} }

.footer-info {
  @apply space-y-3 xl:pr-5 {} }
  .footer-info li {
    @apply flex {} }
  .footer-info .icon {
    @apply w-6 h-6 flex items-center text-current text-base {} }
  .footer-info .content {
    @apply flex-1 body-14 font-medium text-white {} }
    .footer-info .content p {
      @apply body-14 font-medium text-white {} }

.footer-block {
  @apply space-y-3 mb-7 xl:mb-10 {} }

.copyright {
  @apply text-[12px] font-medium text-neutral-gray-200 text-center {} }

.certification-item {
  @apply flex items-center gap-3 text-xs font-medium text-primary-50 text-justify lg:rem:w-[400px] xl:rem:w-[304px] {} }
  .certification-item img {
    @apply size-19 rounded-1 object-contain bg-primary-50 {} }

.footer-top {
  @apply py-10 flex items-center flex-wrap justify-between gap-10 lg:flex-nowrap {} }
  .footer-top .logo {
    @apply aspect-[360/160] rem:max-w-[360px] overflow-hidden {} }
    .footer-top .logo img, .footer-top .logo svg {
      @apply w-full h-full object-cover {} }
  .footer-top .right {
    @apply flex flex-col gap-3 xl:flex-row {} }
  .footer-top .top {
    @apply flex flex-col gap-3 {} }
  .footer-top .iso {
    @apply flex flex-col gap-3 {} }
    .footer-top .iso .certification-item {
      @apply xl:ml-auto xl:mr-0 {} }

.footer-mid {
  @apply py-5 border-t border-[#BB1A29] {} }

@screen md {
  .footer-mid {
    @apply columns-2 gap-8 {} }
    .footer-mid .footer-block {
      @apply break-inside-avoid {} } }

@screen xl {
  .footer-mid {
    @apply columns-4 pb-26 {} } }

.footer-bot {
  @apply py-5 border-t border-[#BB1A29] flex flex-col gap-6 xl:py-8 {} }

footer {
  @apply text-white pb-2 {} }

.pkbm-academic-item {
  @apply bg-neutral-50 {} }
  .pkbm-academic-item .image {
    @apply aspect-[736/414] {} }
  .pkbm-academic-item .caption {
    @apply p-5 flex flex-col gap-4 xl:p-8 {} }
  .pkbm-academic-item .title {
    @apply sub-header-24 font-bold text-black hover:text-primary-900 {} }
  .pkbm-academic-item .brief {
    @apply body-16 font-normal text-black {} }

.pkbm-academic-list {
  @apply grid grid-cols-1 gap-8 md:grid-cols-2 {} }

.accordion-item .accordion-title {
  @apply sub-header-24 font-bold text-primary-900 uppercase flex items-center justify-between gap-3 p-5 bg-neutral-50 cursor-pointer hover:bg-neutral-100 xl:px-8 {} }
  .accordion-item .accordion-title.active {
    @apply bg-primary-900 text-white {} }
    .accordion-item .accordion-title.active > .icon {
      @apply rotate-180 text-white {} }

.accordion-item .icon {
  @apply text-lg text-neutral-500 transition-all {} }

.accordion-item .accordion-content {
  @apply hidden {} }

.accordion-item .accordion-list.is-list-2 > .accordion-item > .accordion-title {
  @apply text-xl font-bold bg-neutral-800 text-white py-4 border-neutral-500 {} }
  .accordion-item .accordion-list.is-list-2 > .accordion-item > .accordion-title .icon {
    @apply text-current {} }
  .accordion-item .accordion-list.is-list-2 > .accordion-item > .accordion-title.active {
    @apply bg-primary-800 border-neutral-800 {} }

.accordion-item .accordion-list.is-list-2 > .accordion-item > .accordion-content {
  @apply p-0 {} }

.accordion-item .accordion-item .accordion-title {
  @apply sub-header-20 font-medium text-black normal-case p-3 border-b border-neutral-100 xl:px-8 {} }
  .accordion-item .accordion-item .accordion-title.active {
    @apply text-primary-900 border-primary-900 bg-neutral-50 {} }
    .accordion-item .accordion-item .accordion-title.active > .icon {
      @apply rotate-180 text-primary-900 {} }

.accordion-item .accordion-item .accordion-content {
  @apply p-5 border border-neutral-200 xl:p-8 {} }

.accordion-item .full-content .box-slogan {
  @apply ml-0 body-18 p-3 {} }

.accordion-item .full-content ul {
  @apply pl-5 {} }
  .accordion-item .full-content ul li {
    @apply marker:text-primary-900 {} }

.pkbm-banner .image {
  @apply aspect-[1920/688] {} }

.pkbm-home-1 .pkbm-desc {
  @apply rem:max-w-[1152px] {} }

.pkbm-home-1 .count-up-list {
  @apply border-y border-neutral-400 {} }

.profile-item {
  @apply text-center border-b border-neutral-200 {} }
  .profile-item .image {
    @apply rounded-full overflow-hidden mx-auto aspect-square img-zoom {} }
  .profile-item .caption {
    @apply flex flex-col justify-center gap-1 p-4 xl:py-8 {} }
  .profile-item .name {
    @apply sub-header-20 font-bold text-red-900 {} }
  .profile-item .sub-name {
    @apply body-14 font-medium text-neutral-500 {} }

.partner-item .image {
  @apply flex-center bg-white h-22 border border-neutral-200 xl:rem:h-[132px] {} }

.pkbm-home-6 .site-desc {
  @apply rem:max-w-[809px] {} }
