header
	box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.05)
	@apply sticky top-0 left-0 right-0 z-999 bg-white
	.header-top
		@apply py-3 transition-all duration-300 ease-in-out xl:rem:pt-[15px]
		.header-right
			@apply flex items-center justify-end gap-2
	.header-bot
		@apply hidden xl:block
	.logo
		@apply rem:h-[85px] transition-all duration-300 ease-in-out
		img, svg
			@apply w-full h-full object-contain
	.button-contact
		& + .button-language
			@apply relative flex items-center
			&::before
				content: ''
				@apply pl-2 border-l border-neutral-100 h-4
	.contact-list
		@apply flex items-center gap-2
		a
			@apply body-14 font-medium text-secondary-200 flex-center gap-2 px-2 rounded-1 rem:py-[6px] hover:text-primary-900 hover:bg-primary-50
			i
				@apply text-primary-900
		li
			@apply leading-none
			& + li
				@apply relative flex items-center
				&::before
					content: ''
					@apply pl-2 border-l border-neutral-100 h-4
	.language-list
		@apply flex items-center gap-2 px-2 rem:py-[6px]
		a
			@apply body-14 font-medium text-secondary-200 flex-center hover:text-primary-900
		li
			@apply leading-none
			& + li
				@apply relative flex items-center
				&::before
					content: ''
					@apply pl-2 border-l border-neutral-100 h-4
			&.active
				a
					text-shadow: 0.75px 0 0 theme('colors.primary.900')
					@apply text-primary-900
	.button-search
		@apply bg-primary-900 flex-center w-17 h-11 rounded-1 text-white text-xl cursor-pointer transition-all hover:bg-primary-950
	.menu-top
		@apply hidden items-center gap-2 xl:flex
		> li
			> a
				@apply body-14 font-medium text-primary-900 flex-center p-3 h-11 bg-neutral-50 rounded-1 hover:bg-primary-50
			&.has-dropdown
				@apply relative
				&:hover
					> a
						@apply bg-primary-900 text-white
					.dropdown-menu
						@apply opacity-100 pointer-events-auto
		.dropdown-menu
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08)
			backdrop-filter: blur(8px)
			@apply absolute z-1 top-full left-0 w-max opacity-0 pointer-events-none transition-all duration-200 ease-in-out
			a
				@apply body-16 font-medium text-neutral-950 flex text-left py-3 px-8 h-12 bg-white border-b border-neutral-200 whitespace-nowrap hover:text-primary-900 hover:border-primary-900
	.main-menu
		@apply flex justify-between items-center
		.dot
			@apply w-1 h-1 bg-white flex-center
		> li
			> a
				@apply body-16 font-bold text-white block text-center py-3 hover-underline
			&.active
				a
					@apply hover-underline-active
			&.has-mega-menu
				// &::before
				// 	content: ''
				// 	@apply absolute top-0 left-0 w-screen h-screen bg-black/50 pointer-events-none -z-1 opacity-0 transition-all duration-200 ease-linear
				&:hover
					@apply before:opacity-100
					> a
						@apply hover-underline-active
					.mega-menu
						@apply opacity-100 pointer-events-auto
	.menu-toggle
		@apply hidden
	.mega-menu
		@apply absolute -z-1 top-full left-0 w-full bg-white shadow flex justify-center max-h-[80vh] overflow-y-auto opacity-0 pointer-events-none transition-opacity duration-200 ease-in-out
	.mega-list
		@apply flex flex-wrap -mx-4 -mt-12 pt-8 pb-11
		> *
			@apply w-1/4 px-4 mt-12
		> li
			> a
				@apply body-16 font-bold text-primary-900 uppercase block pb-3 border-b border-primary-900 hover:text-primary-950 hover:border-current
	.children-menu
		> li
			> a
				@apply block py-3 body-14 font-medium text-neutral-950 border-b border-neutral-200 hover:text-primary-900 hover:border-current
	@media(max-width: 1023.98px)
		.contact-list
			a
				span
					@apply hidden
	@media(max-width: 575.98px)
		.logo
			@apply h-16
		.button-search
			@apply w-11
	@screen xl
		&.active
			.header-top
				@apply py-2
			.logo
				@apply h-15

.mobile-wrap
	@apply fixed top-0 w-screen h-screen bg-white shadow z-998 pt-30 pl-4 pb-4 max-w-md -left-full opacity-0 transition-all duration-500 ease-in-out pointer-events-none xl:hidden
	.navbar-nav-list
		@apply pr-4 max-h-[calc(100vh-120px)] overflow-y-auto
	.main-menu
		@apply space-y-3
		li
			@apply relative
		> li
			> a
				@apply text-[16px] font-bold text-neutral-950 block p-3 bg-neutral-50 rounded-1
			&.active
				> a
					@apply hover-underline-active text-primary-950 bg-primary-900/10
				> .menu-toggle
					@apply text-primary-900
	.menu-toggle
		@apply absolute top-0 right-0 w-11 h-11 flex-center text-neutral-950 cursor-pointer transition-all
		&.active
			@apply rotate-180 text-primary-900
	.mega-menu
		@apply hidden pb-5
	.mega-list
		@apply py-3 px-4 bg-neutral-50 rounded-1 space-y-3 mt-2 -mr-4
		> li
			> a
				@apply text-[16px] font-bold text-neutral-950 block py-2
			&.active
				> a
					@apply text-primary-950
	.children-menu
		@apply pl-7
		> li
			> a
				@apply text-[15px] font-medium text-neutral-950 block py-2
			&.active
				> a
					@apply text-primary-950
	.menu-top
		@apply flex flex-col gap-2 mt-2
		> li
			> a
				@apply text-[16px] font-bold text-black flex text-left p-3 h-12 bg-neutral-50 rounded-1
			&.has-dropdown
				@apply relative
		.dropdown-menu
			// box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08)
			// backdrop-filter: blur(8px)
			// @apply absolute z-1 top-full left-0 w-max opacity-0 pointer-events-none transition-all duration-200 ease-in-out
			@apply mb-2 pl-5 hidden
			a
				@apply body-14 font-medium text-neutral-950 flex text-left py-3 px-8 h-12 bg-white border-b border-neutral-200 whitespace-nowrap hover:text-primary-900 hover:border-primary-900
	.contact-list
		@apply flex flex-col gap-2 mt-2
		a
			@apply body-16 font-medium text-primary-900 flex items-center gap-2 p-3 h-11 bg-neutral-50 rounded-1
			span
				@apply text-secondary-200
	&.open
		@apply left-0 opacity-100 pointer-events-auto

.search-wrap
	@apply fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 max-w-[1000] mx-auto z-[2000] flex-center opacity-0 transition-all duration-300 ease-in-out pointer-events-none px-4 w-full
	.searchbox
		@apply w-full relative
		input
			@apply text-gray-700
		button
			@apply absolute top-1/2 right-0 -translate-y-1/2 w-11 h-11 flex-center text-neutral-950 cursor-pointer transition-all
	&.open
		@apply opacity-100 pointer-events-auto
