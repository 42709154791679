.hub-life-item
	@apply bg-neutral-50 text-neutral-50 cursor-pointer rounded-b-4 overflow-hidden
	.image
		@apply aspect-[480/322] img-zoom
	.caption
		@apply p-5 rounded-b-4 border-b-4 border-current transition-all overflow-hidden xl:px-6 xl:py-8
	.title
		@apply sub-header-20 font-bold text-black transition
	&:hover
		@apply text-primary-900
		.title
			@apply text-current
	&.is-video
		.image
			&::before
				@apply font-awesome content-['\f04b'] text-2xl font-normal text-white w-16 h-16 rounded-full flex-center bg-black/50 absolute-center z-2 pointer-events-none transition duration-200 ease-linear
			&::after
				content: ''
				@apply absolute-center w-full h-full bg-black/20 z-1 pointer-events-none transition-all
		.title
			@apply body-18
		&:hover
			.image
				&::before
					@apply scale-120 opacity-0
				&::after
					@apply opacity-0

.gallery-list
	@apply grid grid-cols-1 gap-8 md:grid-cols-2
	@screen lg
		@apply grid-cols-6
		.hub-life-item
			@apply col-span-2
			&:nth-child(1), &:nth-child(2)
				@apply col-span-3
				.image
					@apply aspect-[736/490]

.video-list
	@apply grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3

.gallery-section
	.site-desc
		@apply rem:max-w-[809px]
