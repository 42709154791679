.header-48
	@apply text-[30px] leading-snug xl:text-5xl

.header-40
	@apply text-[28px] leading-snug xl:text-40

.section-header-32
	@apply text-[24px] leading-tight xl:text-32

.sub-header-24
	@apply text-[20px] leading-snug xl:text-2xl

.sub-header-20
	@apply text-[18px] leading-snug xl:text-xl

.body-18
	@apply text-[17px] leading-tight xl:text-lg

.body-16
	@apply text-[16px] leading-tight xl:text-base

.body-14
	@apply text-[14px] leading-tight xl:text-sm

.label-12
	@apply text-[12px] leading-snug xl:text-sm

.regular-18
	@apply text-[16px] leading-normal xl:text-lg

.site-title
	@apply header-48 font-bold text-primary-900

.site-sub-title
	@apply section-header-32 font-bold text-black

.site-desc
	@apply body-16 font-normal text-black
	> *
		@apply my-4 first:mt-0 last:mb-0
	ul
		@apply list-disc pl-5 space-y-4

.full-content
	@apply prose max-w-none
	@apply body-16 font-normal text-black leading-normal
	> *
		@apply first:mt-0 last:mb-0
	p
		img
			@apply m-0
	th, td
		@apply rem:py-[10px]
	tbody
		tr
			@apply border-y border-neutral-300
		th
			@apply font-bold text-primary-900

.fullcontent
	@apply font-normal leading-[1.4] text-neutral-090909 text-justify pt-3
	*
		@apply font-normal leading-[1.4] text-neutral-090909 text-justify
	@media screen and ( max-width: 1024px)
		@apply text-base
		*
			@apply text-base
	img,iframe
		@apply block mx-auto my-4  overflow-hidden
		margin: 16px auto !important
	p
		@apply mb-3 text-base
		*
			@apply text-base
		@media screen and ( max-width: 1024px)
			@apply text-base
			*
				@apply text-base
	ul,ol
		list-style-type: disc
		padding-left: 0
		li
			@apply mb-3 flex items-start
			&::before
				content: ''
				@apply block mt-2 pointer-events-none z-10  transition w-[8px] h-[8px] rounded-full mr-3
				min-width: 8px
	hr
		@apply block my-4

	h2,h3,h4,h5
		@apply mb-4 font-bold text-primary-900
	strong
		font-weight: 700 !important
		*
			font-weight: 700 !important
	h2
		@apply text-4xl
		@media screen and ( max-width: 576px)
			@apply text-3xl
	h3
		@apply text-3xl
		@media screen and ( max-width: 576px)
			@apply text-2xl
	h4
		@apply text-xl
	h5
		@apply text-lg
	.row
		@apply mb-0
	table
		@apply w-full
		tbody
			td
				@apply text-black text-base py-3 font-normal border-t border-t-neutral-300 border-b border-b-neutral-300
				*
					@apply text-black text-base font-normal
				&:first-child
					@apply font-bold text-primary-900
					*
						@apply font-bold text-primary-900
				&:nth-child(2)
					@apply pl-6
