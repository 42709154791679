.notification-item
	@apply flex flex-col gap-5 transition-all p-5 bg-neutral-50 xl:p-8 xl:gap-6
	.date
		@apply bg-primary-900 flex-center flex-col w-18 h-18 text-white transition-all
	.day
		@apply sub-header-24 font-bold
	.month-year
		@apply label-12 font-normal
	.caption
		@apply flex-1 flex flex-col gap-8 xl:gap-15
	.news-title
		@apply body-16 font-bold text-neutral-950 line-clamp-3 transition-all
	.link
		background-size: 0
		@apply text-neutral-950 transition-all w-max
	&:hover
		@apply bg-primary-900
		.date
			@apply bg-white text-primary-900
		.news-title
			@apply text-white
		.link
			@apply text-white

.notification-section
	.notification-list
		@apply grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4
