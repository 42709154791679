.student-item
	box-shadow: 0px 4px 20px 0px #0000001F
	@apply rounded-b-4 overflow-hidden bg-white
	.image
		@apply aspect-[352/234]
	.title
		@apply body-18 font-bold text-black py-6 px-5 flex-center transition-all hover:text-primary-900
	&:hover
		.title
			@apply text-primary-900

.student-list
	@apply grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4
