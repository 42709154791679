.academic-detail-section
	.academic-list
		@apply grid grid-cols-1 gap-8 sm:grid-cols-2 xl:gap-y-9
	.academic-item
		.image
			@apply aspect-[544/306]
		.caption
			@apply xl:px-8
		.title
			@apply xl:text-xl
