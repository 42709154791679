.box-new-notification
	@apply p-5 bg-neutral-50 xl:p-8
	.notification-list
		@apply mt-5
	.notification-item
		@apply flex-row gap-5 py-5 px-0 bg-transparent border-t border-neutral-200 first:border-none first:pt-0 last:pb-0 hover:bg-transparent
		.news-title
			@apply body-14 font-medium line-clamp-4
		&:hover
			.news-title
				@apply text-primary-900
