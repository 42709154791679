.modal
	@apply bg-transparent bg-none p-0 hidden
	.modal-wrap
		@apply bg-white p-5 rounded-2 shadow xl:p-10

.modal-md
	@apply rem:max-w-[768px]

.modal-lg
	@apply rem:max-w-[1024px]

.modal-xl
	@apply rem:max-w-[1280px]

.modal-head

.modal-body
