.contact-item
	@apply bg-neutral-50 border-t-4 border-primary-900 p-5 md:p-10 xl:p-12
	.icon
		@apply text-32 text-black mb-3
	.title
		@apply body-16 font-bold text-black uppercase
	.line
		@apply w-20 mx-auto h-px bg-neutral-300 my-4
	.content
		@apply body-16 font-normal text-black
		> *
			@apply mb-2 last:mb-0
		a
			@apply text-primary-500 underline hover:text-primary-700

.contact-list
	@apply grid grid-cols-1 md:grid-cols-2 gap-8 lg:grid-cols-3

.contact-form
	.txt
		@apply text-base text-neutral-090909
		*
			@apply text-base text-neutral-090909
		span
			@apply text-primary-900
			*
				@apply text-primary-900
	.wrap-form
		.form-group
			input[type='text'],input[type='email'],input[type='tel'],textarea,select
				@apply bg-neutral-50 border-transparent rounded-none h-[44px] px-5 text-neutral-950
				&::placeholder
					@apply text-neutral-700 font-normal text-base
			textarea
				height: 120px
		.frm-btnwrap
			button
				@apply bg-primary-900 border border-primary-900 rounded-1 overflow-hidden h-[44px] w-[160px] px-4 py-2 transition
				span,em
					@apply leading-none text-base text-white transition
				span
					@apply font-medium uppercase
				em
					@apply ml-2
				&:hover
					@apply bg-white
					span,em
						@apply text-primary-900
	.block-wrap
		@apply flex flex-col justify-center h-full
	.img
		@media screen and ( max-width: 1024px)
			@apply pt-8
		a
			@apply flex aspect-[1/1]
