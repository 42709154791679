.global-breadcrumb
	@apply bg-neutral-50
	.breadcrumb
		@apply flex flex-wrap items-center py-3 gap-x-3 gap-y-2
		a
			@apply body-14 font-normal text-neutral-500 leading-normal
		li
			@apply flex items-center last:after:hidden
			&::after
				content: ''
				@apply ml-3 w-px h-6 bg-neutral-200
			&:first-child
				a
					@apply text-0
					span
						@apply hidden
					&::before
						@apply font-awesome content-['\f015'] text-neutral-500 text-base font-normal leading-none flex-center
