.faq-list
	counter-reset: section
	.toggle-item
		@apply overflow-hidden mb-5
		&.is-toggle
			box-shadow: 2px 2px 12px 2px #00000014
			.title
				@apply border-primary-900
				.number
					@apply bg-primary-900 text-white
				em
					transform: rotateX(180deg)
					@apply text-primary-900
			.article
				@apply block
		.title
			@apply flex justify-start items-center gap-5 bg-neutral-50 rounded-1 overflow-hidden border-b border-neutral-50 cursor-pointer transition-all
		.number
			@apply body-18 font-bold text-neutral-500 flex-center w-15 h-15 rounded-1 bg-neutral-200 transition-all
		.txt
			@apply flex-1 flex justify-start items-center transition py-2 relative bg-neutral-50 pr-10 rounded
			@screen lg
				@apply w-full m-0
			> span
				@apply font-bold text-lg
				*
					@apply font-bold text-lg

			em
				@apply ml-5 inline-block absolute top-1/2 right-[20px]
				transform: translateY(-50%)
				font-size: 18px
				height: 15px
				width: 16px
				transition: .3s all  ease-in-out
				@media screen and ( max-width: 1023.98px)
					font-size: 16px
					height: 16px
					width: 16px
.toggle-item
	.article
		@apply hidden  pt-3 font-normal text-neutral-090909 text-base  border-t border-t-transparent
		padding: r(20px) r(20px) 0 r(20px)
		@screen lg
			padding: r(20px) r(50px)
		*
			@apply font-normal text-neutral-090909 text-base
		p
			@apply block mb-4
		ul
			@apply mt-4
			padding-left: 20px
			list-style-type: disc
			li
				@apply mb-4
		strong,h3,h4
			@apply font-bold
		img
			@apply block mx-auto my-4
		ul
			list-style-type: disc
			li
				@apply mb-2
