.news-item-big
	@apply relative
	&::after
		content: ''
		@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
		background: linear-gradient(342.92deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 76.5%)
	.img
		a
			@apply flex aspect-[352/234]
	.txt
		@apply absolute bottom-0 left-0 right-0 z-20 p-6 pointer-events-none
		@screen xl
			@apply p-8
		span
			@apply sub-header-20 text-white font-bold
			display: -webkit-box
			-webkit-line-clamp: 2
			-webkit-box-orient: vertical
			overflow: hidden
	time
		@apply text-neutral-200 font-medium mb-2 block
		font-size: 12px
	&:hover
		.txt
			span
				@apply underline

// .news-item
// 	.img
// 		a
// 			@apply flex aspect-[352/234]
// 	time
// 		@apply text-neutral-400 font-medium mb-2 block
// 		font-size: 14px
// 	.txt
// 		@apply p-5 bg-neutral-50
// 		@screen xl
// 			@apply p-6
// 	.title
// 		a
// 			@apply transition block leading-[1.4]
// 			display: -webkit-box
// 			-webkit-line-clamp: 3
// 			-webkit-box-orient: vertical
// 			overflow: hidden
// 	&:hover
// 		.title
// 			a
// 				@apply text-primary-900

.news-list-section
	.news-list
		@apply grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4
	.news-item
		@apply bg-neutral-50
	@screen xl
		.news-item
			&:nth-child(1), &:nth-child(2)
				@apply col-span-2 rounded-none
				.image
					@apply aspect-[736/484]
				.caption
					background: linear-gradient(0, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 76.5%)
					@apply absolute bottom-0 left-0 z-1 w-full bg-transparent rounded-none border-0 p-8 rem:pt-[153px] gap-3 justify-end pointer-events-none
					> *
						@apply pointer-events-auto
				.news-date
					@apply text-neutral-200
				.news-title
					@apply text-white sub-header-20
