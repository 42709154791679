// button toggle menu
#buttonMenu
	@apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none m-0 p-0 bg-none ml-3
	.line
		@apply absolute block h-[2px] w-full bg-primary-900 rounded-full opacity-100 left-0 rotate-0 transition-all
		&:nth-child(1)
			@apply top-[4px]
		&:nth-child(2)
			@apply top-1/2 -translate-y-1/2
		&:nth-child(3)
			@apply bottom-[4px]
	#pulseMe
		@apply flex justify-center items-center absolute top-1/2 left-1/2 min-w-[34px] w-[30px] h-[34px] -translate-x-1/2 -translate-y-1/2 z-1
	.bar
		@apply bg-primary-900 rounded-1 absolute shadow-bar
		&.left
			@apply w-px animate-left-bar
		&.top
			@apply h-px animate-top-bar
		&.right
			@apply w-px animate-right-bar
		&.bottom
			@apply h-px animate-bottom-bar
	&.open, &:hover
		.line
			&:nth-child(2)
				@apply w-[15px]
		.bar
			@apply hidden
	@screen xl
		display: none !important

// alert
.alert
	@apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm
	& button
		@apply hidden
	&.alert-danger
		color: #721c24
		background-color: #f8d7da
		border-color: #f5c6cb
	&.alert-success
		color: #155724
		background-color: #d4edda
		border-color: #c3e6cb

.backdrop
	backdrop-filter: blur(8px)
	@apply fixed z-[500] w-full h-full top-0 left-0 bg-black/50 pointer-events-none opacity-0 transition-all
	&.open
		@apply opacity-100 pointer-events-auto
	&.search-backdrop
		@apply z-[1500]

.cta-fixed
	@apply fixed z-100 bottom-10 right-3 xl:right-10
	ul
		@apply space-y-1
	li
		> *
			@apply flex-center flex-col gap-1 w-16 h-16 bg-neutral-gray-300 rem:text-[12px] font-medium text-white shadow hover:bg-primary-800
		i
			@apply text-2xl w-8 h-8 flex-center
		img
			@apply w-7 h-7 object-contain
	.back-to-top
		@apply opacity-0 pointer-events-none  transition-all duration-200
		> *
			@apply bg-primary-800 hover:bg-primary-950
		&.active
			@apply opacity-100 pointer-events-auto

.social-list
	ul
		@apply flex flex-wrap justify-center rem:gap-[10px]
	a
		@apply w-12 h-12 rounded-1 flex-center text-white text-xl bg-[#BB1A29] shadow hover:bg-primary-950
