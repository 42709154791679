.pkbm-academic-item
	@apply bg-neutral-50
	.image
		@apply aspect-[736/414]
	.caption
		@apply p-5 flex flex-col gap-4 xl:p-8
	.title
		@apply sub-header-24 font-bold text-black hover:text-primary-900
	.brief
		@apply body-16 font-normal text-black

.pkbm-academic-list
	@apply grid grid-cols-1 gap-8 md:grid-cols-2

.accordion-item
	.accordion-title
		@apply sub-header-24 font-bold text-primary-900 uppercase flex items-center justify-between gap-3 p-5 bg-neutral-50 cursor-pointer hover:bg-neutral-100 xl:px-8
		&.active
			@apply bg-primary-900 text-white
			> .icon
				@apply rotate-180 text-white

	.icon
		@apply text-lg text-neutral-500 transition-all
	.accordion-content
		@apply hidden
	.accordion-list
		&.is-list-2
			> .accordion-item
				> .accordion-title
					@apply text-xl font-bold bg-neutral-800 text-white py-4 border-neutral-500
					.icon
						@apply text-current
					&.active
						@apply bg-primary-800 border-neutral-800
				> .accordion-content
					@apply p-0
	.accordion-item
		.accordion-title
			@apply sub-header-20 font-medium text-black normal-case p-3 border-b border-neutral-100 xl:px-8
			&.active
				@apply text-primary-900 border-primary-900 bg-neutral-50
				> .icon
					@apply rotate-180 text-primary-900
		.accordion-content
			@apply p-5 border border-neutral-200 xl:p-8
	.full-content
		.box-slogan
			@apply ml-0 body-18 p-3
		ul
			@apply pl-5
			li
				@apply marker:text-primary-900
