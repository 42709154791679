@import 'src/components/_core/_mixins'

.edit-link i:before
	content: '✏️'

html, body
	@apply scroll-pt-22 md:scroll-pt-27 xl:scroll-pt-30

main
	// @apply mt-22 md:mt-27 xl:mt-30

.tabslet-tabs
	@apply flex items-center gap-4 overflow-x-auto md:overflow-visible md:justify-center
	a
		@apply flex-center text-center body-16 font-medium text-neutral-950 hover-underline whitespace-nowrap
	li
		@apply w-max
		& + li
			@apply md:pl-4 md:border-l md:border-neutral-300
		&.active
			a
				@apply text-primary-900 hover-underline-active

.nav-section
	@apply bg-white sticky top-22 z-[997] px-4 md:top-27 xl:top-30
	&::before
		content: ''
		@apply absolute bottom-0 left-0 w-full h-px bg-neutral-300 pointer-events-none z-1
	.nav-heading-flex
		@apply flex gap-3 xl:gap-6
	.nav-heading
		@apply body-18 font-bold text-white bg-primary-900 p-3 hidden md:block xl:px-5 xl:py-6
	.nav-list
		@apply relative z-1 flex gap-4 overflow-x-auto p-0 md:overflow-visible md:justify-center
		a
			@apply body-16 font-medium text-neutral-950 hover-underline flex-center text-center rem:py-[10px] hover-underline
		li
			& + li
				@apply pl-4 relative
				&::before
					content: ''
					@apply absolute top-1/2 -translate-y-1/2 left-0 w-px h-4 bg-neutral-300 pointer-events-none
			&.active
				a
					@apply text-primary-900 hover-underline-active
	&.is-pkbm
		@screen xl
			.nav-list
				@apply gap-8
				li
					& + li
						@apply pl-8
