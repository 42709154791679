.box-news-detail
	@apply rem:max-w-[1088px]

.box-news-other
	.box-title
		@apply pb-5 border-b border-neutral-200
	.news-item
		@apply flex-row items-center gap-4 py-5 border-b border-neutral-200 rounded-none
		.image
			@apply w-[112px] h-[76px]
		.caption
			background-size: 0
			@apply flex-1 flex flex-col gap-1 p-0 rounded-none border-none
		.news-date
			@apply text-[12px] font-medium m-0
		.news-title
			@apply body-14 font-medium text-black line-clamp-3 hover:text-primary-900
