.nav-list
	@apply flex items-center gap-1 p-1 overflow-x-auto overflow-y-hidden
	.btn-solid
		@apply text-sm
		i, em
			@apply text-xl
	li
		@apply w-max
		a
			@apply whitespace-nowrap

.home-nav
	@apply sticky top-22 z-10 md:top-27 xl:top-30
	.nav-list
		@apply xl:justify-center
	@media(max-width: 1279.98px)
		.nav-list
			align-items: normal
			@apply overflow-visible
			li
				@apply flex-1 h-auto
				a
					@apply whitespace-normal flex-col text-center h-full justify-start p-3
	@media(max-width: 575.98px)
		@apply static
		.nav-list
			@apply flex-wrap
			li
				@apply basis-1/4

.hub-title
	@apply space-y-7 xl:space-y-12
	.university-name
		letter-spacing: 6.4px
		-webkit-text-stroke-width: 1
		-webkit-text-stroke-color: rgba(255, 224, 195, 0.88)
		@apply rem:text-[40px] font-extrabold text-[#FFE0C3]/88 uppercase leading-[calc(90/80)] xl:rem:text-[80px]
		.highlight
			// @apply block
			// &::first-letter
			@apply text-white
	h2
		@apply rem:text-[30px] font-bold text-white xl:rem:text-[48px]

.home-about
	@apply xl:pt-20 xl:pb-33
	.block-about
		@apply space-y-7 xl:space-y-12
	.about-list
		@apply columns-1 gap-8 break-inside-avoid md:columns-2
	.about-item
		@apply mb-8 lg:first:mt-15 xl:rem:min-h-[304px]

.home-news
	&:is(.news-list-section)
		.news-list
			@apply grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-12
		@screen xl
			.news-item
				@apply col-span-4
				&:nth-child(1), &:nth-child(2)
					@apply col-span-6 rounded-none

.home-courses-item
	@apply bg-white rounded-b-4 overflow-hidden relative shadow-1
	.image
		@apply aspect-[352/234] img-zoom
	.caption
		@apply p-5 xl:p-6
	.title
		@apply body-18 font-bold text-black transition-all text-center uppercase
	&:hover
		.title
			@apply text-primary-900

.home-courses-list
	@apply grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4

.home-courses
	.site-desc
		@apply rem:max-w-[956px]
	.count-up-list
		@apply border-y border-neutral-400

.home-committed
	.home-courses-item
		.caption
			@apply px-3
	// @apply relative md:rem:min-h-[720px]
	// .box-content
	// 	@apply relative z-2 flex flex-col gap-5 py-10 md:pt-30 md:pb-25 md:rem:max-w-[484px]
	// .button
	// 	@apply flex-col gap-3
	// .btn-solid
	// 	@apply w-max xl:px-6
	// @screen md
		// .image
		// 	@apply absolute inset-0 z-1
		// .site-title,.site-desc
		// 	@apply text-white

.story-item
	.image
		@apply aspect-[864/448] rounded-4
	.caption
		@apply bg-white p-5 flex flex-col rounded-4 border-b-2 border-neutral-200 md:p-10 xl:px-16 xl:py-14
	.name
		@apply sub-header-20 font-bold text-primary-900
	.sub-name
		@apply body-14 font-medium text-neutral-500
	.line-dot
		@apply flex items-center rem:gap-[2px]
		.dot
			@apply w-1 h-1 bg-primary-500 even:bg-primary-900
	.brief
		@apply body-14 font-medium text-black
	@screen md
		@apply flex flex-row-reverse
		> *
			@apply w-1/2
		.caption
			@apply border-b-0 border-l-2
	@screen xl
		.image
			@apply rem:w-[864px] flex-none
		.caption
			@apply flex-1

.home-story
	.swiper-relative
		@apply pb-10
	.swiper-pagination
		@apply bottom-0
	@screen xl
		.swiper
			@apply overflow-visible rem:max-w-[1312px]

.unit-link-list
	@apply flex-center flex-wrap gap-5
	li
		@apply pr-5 border-r border-neutral-400 last:pr-0 last:border-none
	a
		@apply block text-center sub-header-20 font-normal text-black leading-[1.4] hover-underline hover:text-primary-900

.home-our-research
	.site-desc
		@apply rem:max-w-[809px]
	.count-up-list
		@apply border-y border-neutral-400

.home-connectivity
	.site-desc
		@apply rem:max-w-[849px]
	.count-up-list
		@apply border-y border-neutral-400
	@screen xl
		.swiper
			@apply -mx-2 px-2 pb-2
		.home-courses-item
			@apply shadow

.home-hub-life
	@apply relative md:rem:min-h-[560px]
	.box-content
		@apply relative z-2 flex flex-col gap-5 py-10 md:py-19 md:rem:max-w-[484px]
	.button
		@apply flex-col gap-3
	.btn-solid
		@apply w-max xl:px-6
	@screen md
		.image
			@apply absolute inset-0 z-1
