.swiper-pagination
	.swiper-pagination-bullet
		@apply opacity-100 rem:w-[10px] rem:h-[15px] rounded-none bg-transparent text-primary-800/50 flex items-end
		&::before
			content: ''
			@apply w-full rem:h-[10px] bg-current transition-all
		&.swiper-pagination-bullet-active
			@apply text-primary-800
			@apply before:h-full
	&.is-white
		.swiper-pagination-bullet
			@apply text-white/50
			&.swiper-pagination-bullet-active
				@apply text-white
	&.is-abs
		@apply absolute bottom-3 left-0 z-1 w-full flex-center md:bottom-10

.swiper-button
	> *
		@apply text-2xl text-primary-900 w-12 h-12 rounded-1 border border-primary-900 bg-transparent flex-center transition-all cursor-pointer hover:text-white hover:bg-primary-900
		&.swiper-button-disabled
			@apply opacity-40
		&.swiper-button-lock
			@apply opacity-0 pointer-events-none
	&.is-white
		> *
			@apply text-white bg-white/20 border-white hover:text-neutral-200
	&.is-abs
		> *
			@apply absolute top-1/2 -translate-y-1/2 z-1
		.button-prev
			@apply left-1 xl:-left-20
		.button-next
			@apply right-1 xl:-right-20
		&.is-top-20
			> *
				@apply top-[20%]
		&.is-top-30
			> *
				@apply top-[30%]
		&.is-top-40
			> *
				@apply top-[40%]
		&.is-top-45
			> *
				@apply top-[45%]

.swiper-relative
	@apply lg:px-10 xl:px-0
	.swiper-button
		&.is-absolute
			.button-prev
				@apply left-0 xl:-left-12 2xl:-left-22
			.button-next
				@apply right-0 xl:-right-12 2xl:-right-22
	.swiper-scrollbar
		@apply h-[2px] bg-neutral-300
		.swiper-scrollbar-drag
			@apply bg-primary-700
